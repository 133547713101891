import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  progress: {
    background: 'rgba(163, 231, 161, 0.7)',
    width: '100%',
    height: 0,
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
}));
