import { AxiosProgressEvent } from 'axios';
import { sahinAlertDialog } from 'common/components/AlertDialog/AlertDialog';
import { AllTablePaths } from 'common/model/AllTablePaths';
import { fetcher } from 'common/utils/helper';
import { axios } from './axiosInit';

export const getAPI = (savePath: AllTablePaths, id?: string | number) => {
  const path = id ? `${savePath}/${id}` : savePath;
  return fetcher(path, {
    method: 'GET',
  });
};

export const postAPI = <T extends object>(
  savePath: AllTablePaths,
  data: T,
  id?: number | string,
) => {
  // const formData = new FormData();
  // Object.keys(data).forEach((key) =>
  //   formData.append(key, data[key as keyof T] as string),
  // );

  const path = id ? `${savePath}/${id}` : savePath;
  return axios
    .post(path, data)
    .then((res) => {
      if (res.data.message) {
        sahinAlertDialog({
          message: res.data.message,
          type: res.data.status,
        });
      }
      return res.data;
    })
    .catch((error) => console.error(error));
};

export const updateAPI = <T extends object>(
  savePath: AllTablePaths,
  id: string | number,
  data: T,
) => {
  // const formData = new FormData();
  // Object.keys(data).forEach((key) =>
  //   formData.append(key, data[key as keyof T] as string),
  // );
  return axios
    .put(`${savePath}/${id}`, data)
    .then((res) => {
      if (res.data.message) {
        sahinAlertDialog({
          message: res.data.message,
          type: 'success',
        });
      }
      return res.data;
    })
    .catch((error) => console.error(error));
};

export const deleteAPI = (savePath: AllTablePaths, id: number) => {
  return axios
    .delete(`${savePath}/${id}`)
    .then((res) => {
      if (res.data.message) {
        sahinAlertDialog({
          message: res.data.message,
          type: 'success',
        });
      }
      return res.data;
    })
    .catch((error) => console.error(error));
};

export const uploadAPI = (
  savePath: AllTablePaths,
  data: any,
  onProgress?: (api: AxiosProgressEvent) => void,
) => {
  const formData = new FormData();
  formData.append('image', data);

  return axios
    .post(`${savePath}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: onProgress,
    })
    .then((res) => {
      if (res.data.message) {
        sahinAlertDialog({
          message: res.data.message,
          type: res.data.status,
        });
      }
      const uploaded = res.data?.data;
      return uploaded;
    })
    .catch((error) => console.error(error));
};
