import ControlPointRoundedIcon from '@mui/icons-material/ControlPointRounded';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { useStyles } from './ListTopBar.styles';

export const ListTopBar = ({ onAdd, onDelete, selected, toolbox }: any) => {
  const { classes } = useStyles();

  return (
    <AppBar position="static">
      <Toolbar className={classes.root}>
        <Grid justifyContent="space-between" container>
          <Grid item>
            {onAdd && (
              <IconButton onClick={onAdd}>
                <ControlPointRoundedIcon />
              </IconButton>
            )}
          </Grid>
          <Grid item>
            {onDelete && (
              <IconButton
                onClick={() => onDelete(selected)}
                disabled={!selected}
              >
                <DeleteOutlineIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
        <Grid justifyContent="end" container>
          {toolbox}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
