import { BlogWrapper } from 'blog/components/BlogWrapper/BlogWrapper';

export const About = () => {
  const title = 'Hakkımızda';
  const content = `
  Biz Kimiz?

  Hoş geldiniz! Biz, lezzetli yemeklerin ve keyifli bir atmosferin birleşimini sunan bir ekibiz. Misafirlerimize unutulmaz bir deneyim yaşatmak için buradayız. İşte bizim hikayemiz:

  Lezzet ve Yaratıcılık:
  Restoranımız, mutfakta ustalıkla hazırlanan özenle seçilmiş lezzetlerle dolu bir menü sunar. Şeflerimiz, her bir yemeği özel kılan taze ve kaliteli malzemelerle çalışarak, damak zevkinize hitap etmek için özgün tarifler geliştirir.

  Müşteri Memnuniyeti:
  Misafirlerimizin memnuniyeti, en üst önceliğimizdir. Sıcak bir atmosferde, güleryüzlü hizmet anlayışımızla sizleri ağırlamaktan büyük mutluluk duyuyoruz. Her anınızın özel olması için çaba sarf ediyoruz.

  Teknoloji ve Hız:
  Müşterilerimize en iyi hizmeti sunabilmek adına teknolojiyi yakından takip ediyoruz. QR menümüz, size daha hızlı ve pratik bir yemek deneyimi sunmak için tasarlanmıştır. Menümüzü tarayarak anında sipariş verebilir, özel tekliflerimizi keşfedebilirsiniz.

  Sürdürülebilirlik ve Toplumsal Sorumluluk:
  Çevreye duyarlı bir yaklaşım benimseyerek, sürdürülebilirlik ilkelerini destekliyor ve yerel topluluklarımıza katkı sağlamak için çeşitli projelere destek veriyoruz. Yemeklerimizin lezzeti kadar, sosyal sorumluluk projelerine olan katkımızla da gurur duyuyoruz.

  Bağlantı Kurun:
  Sizleri ağırlamaktan ve sizi lezzetli bir yolculuğa çıkarmaktan mutluluk duyarız. İster arkadaşlarınızla bir araya gelin, ister iş toplantıları için tercih edin; her zaman sıcak bir karşılama ve nefis yemekler sizi bekliyor.

  Teşekkür ederiz ki, bizimle birlikte olduğunuz için. Daha fazla bilgi veya rezervasyon için bizimle iletişime geçmekten çekinmeyin.

  MENU KOLAY
`;

  return <BlogWrapper title={title} content={content} />;
};
