import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((_theme) => ({
  root: {
    background: _theme.palette.background.paper,
    color: '#6e6e6e',
  },
  drawer: {
    width: '100%',
    position: 'relative',
  },
  paper: {
    width: '100%',
    position: 'absolute!important' as 'absolute',
  },
  listItem: {
    margin: '10px 0!important',
    color: '#6e6e6e',
    '& svg': {
      color: '#6e6e6e',
    },
    '&:hover': {
      '& svg': {
        color: '#9e9e9e',
      },
    },
  },
  listItemClosed: {
    padding: '10px 0!important',
  },
  icon: {
    minWidth: '35px!important',
  },
}));
