import { createSlice } from '@reduxjs/toolkit';
import { AdminCacheNames } from 'common/model/AdminCacheNames';
import { Brand } from 'common/model/Brand';
import { Contact } from 'common/model/Contact';
import { Nullable } from 'common/model/Nullable';
import { ThemeMode } from 'common/model/ThemeMode';
import { getCache, setCache } from 'common/utils/helper';
import { Menu } from 'menu/models/Menu';
import { Setting } from 'settings/models/Settings';

interface CommonContext {
  theme: ThemeMode;
  auth: any;
  contacts: Contact[];
  settings?: Nullable<Setting>;
  brand?: Nullable<Brand>;
  menus?: Nullable<Menu[]>;
}

const initialState: CommonContext = {
  // Theme
  theme: ThemeMode.LIGHT,

  //Auth
  auth: getCache(AdminCacheNames.AUTH, null),

  // Contacts
  contacts: [],
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setContacts: (state, { payload }: { payload: Contact[] }) => {
      state.contacts = payload;
    },
    setAuth: (state, { payload }: { payload: any }) => {
      setCache(AdminCacheNames.AUTH, payload);
      state.auth = payload;
    },
    setSettings: (state, { payload }: { payload: Setting }) => {
      state.settings = payload;
    },
    setBrand: (state, { payload }: { payload: Brand }) => {
      state.brand = payload;
    },
    setMenus: (state, { payload }: { payload: Menu[] }) => {
      state.menus = payload;
    },
    setTheme: (state, { payload }: { payload: ThemeMode }) => {
      state.theme = payload;
    },
  },
});

export const commonActions = commonSlice.actions;
export const commonReducer = commonSlice.reducer;
