import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { GlobalCss } from 'common/components/GlobalCss/GlobalCss';
import { useAppSelector } from 'common/context/hooks';
import { lightTheme, darkTheme } from 'common/helper/theme';
import { ThemeMode } from 'common/model/ThemeMode';

export const ThemeProvider = ({ children }: any) => {
  const themeMode = useAppSelector((state) => state.common.theme);

  return (
    <MuiThemeProvider
      theme={themeMode === ThemeMode.LIGHT ? lightTheme : darkTheme}
    >
      <GlobalCss />
      {children}
    </MuiThemeProvider>
  );
};
