import { Box, Grid, Paper, Tab, Tabs } from '@mui/material';
import { SyntheticEvent, useState } from 'react';
import { useStyles } from './Tab.styles';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const TabPanel = ({
  children,
  value,
  index,
  ...other
}: TabPanelProps) => {
  const { classes } = useStyles();

  return (
    <div
      className={classes.root}
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

export const SahinTabs = ({ data }: any) => {
  const { classes } = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Grid rowSpacing={2} item container>
      <Grid item container>
        <Grid component={Paper} item container>
          <Tabs value={value} onChange={handleChange}>
            {data.map((item: any, index: number) => (
              <Tab key={index} label={item.label} className={classes.tab} />
            ))}
          </Tabs>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid component={Paper} sx={{ py: 5 }} item container>
          {data.map((item: any, index: number) => (
            <TabPanel key={index} value={value} index={index}>
              {item.content}
            </TabPanel>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
