import { BlogWrapper } from 'blog/components/BlogWrapper/BlogWrapper';

export const WhyRk = () => {
  const title = 'Neden Menu Kolay';
  const content = `
Qr menuler, özellikle maliyeti optimum tutmak isteyen işletmeler için vazgeçilmezdir

İşte bu avantajlardan bazıları:

<b>Temassız Deneyim:</b> <small>QR menüler, müşterilere fiziksel menülerle temas etme ihtiyacını ortadan kaldırarak daha hijyenik bir deneyim sunar. Özellikle salgın dönemlerinde, temassız hizmetlerin sağlanması önemli hale gelmiştir.</small>

<b>Maliyet ve Çevre Dostu:</b>  <small>QR menüler, geleneksel kağıt menülerin basım, güncelleme ve depolama maliyetlerini ortadan kaldırır. Bu hem işletme için tasarruf sağlar hem de çevre dostu bir yaklaşımı destekler.</small>

<b>Anında Güncellenebilirlik:</b> <small>QR menüler, anında güncellenebilir ve değiştirilebilir. Bu özellik, restoran sahiplerinin menülerini mevsimlere, özel etkinliklere veya günlük stok durumuna göre kolayca adapte etmelerini sağlar.</small>

<b>Daha Çok Bilgi ve Görsel Sunum:</b> <small>QR menüler, her yemeğin veya içeceğin yanında detaylı açıklamalar, fotoğraflar veya müşteri yorumları gibi ek bilgileri sunma imkanı tanır. Bu, müşterilere daha bilinçli seçimler yapmalarında yardımcı olabilir.</small>

<b>Sipariş Kolaylığı:</b> <small>QR menüler, müşterilere masa başında veya mobil cihazlarından doğrudan sipariş verme imkanı sunar. Bu, servis hızını artırabilir ve müşteri memnuniyetini artırabilir.</small>

<b>Promosyon ve İndirimlerin Kolayca Tanıtılabilirliği:</b> <small>QR menüler, özel promosyonlar, indirimler veya sadakat programları gibi müşterilere yönelik avantajları hızlı bir şekilde tanıtma ve güncelleme imkanı sunar.</small>

<b>Çeşitli Dil Desteği:</b> <small>QR menüler, çoklu dil seçenekleri sunabilir, böylece farklı dil konuşan müşterilere daha iyi hizmet verebilir.</small>

Bu avantajlar, satıcıların <b>işlerini büyütmelerine</b>, karlılıklarını artırmalarına ve müşterilerine daha iyi hizmet vermelerine yardımcı olur. Bu nedenle, komisyon olmayan aylık tek alım paketleri sunan e-sipariş siteleri, çeşitli sektörlerdeki satıcılar için ideal bir seçenek olabilir.`;

  return <BlogWrapper title={title} html={content} component="pre" />;
};
