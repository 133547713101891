import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useState } from 'react';
import { useStyles } from './Image.styles';
import { GridProps } from '@mui/material';

type ImageProps = GridProps & {
  src: string;
  alt?: string;
  width?: number | string;
  height?: number | string;
  aspectRatio?: string; // = '1/1'
  objectFit?: 'cover' | 'contain' | 'fill';
  progress?: number;
};

export const Image = ({
  src,
  alt,
  width,
  height,
  aspectRatio, // = '1/1'
  objectFit,
  progress,
  ...rest
}: ImageProps) => {
  const { classes } = useStyles();
  const [visible, setVisible] = useState(false);

  const onLoad = () => {
    setVisible(true);
  };

  return (
    <>
      {src && !visible && (
        <Skeleton variant="rectangular" width={width} height={height} />
      )}
      <Grid
        {...rest}
        sx={{
          width,
          height,
          aspectRatio,
          ...((!visible && {
            visibility: 'hidden',
            height: 0,
          }) ||
            {}),
          position: 'relative',
        }}
        justifyContent="center"
        container
      >
        <img
          src={src}
          alt={alt}
          onLoad={onLoad}
          style={{
            ...((width || 0) > (height || 0) ? { width } : { height }),
            maxWidth: width,
            objectFit,
          }}
        />
        {!!progress && (
          <span
            className={classes.progress}
            style={{ height: `${progress}%` }}
          />
        )}
      </Grid>
    </>
  );
};
