import { configureStore } from '@reduxjs/toolkit';
import { accountReducer } from 'account/common/context/AccountSlice';
import { commonReducer } from './CommonSlice';

export const store = configureStore({
  reducer: {
    common: commonReducer,
    account: accountReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
