import { Grid } from '@mui/material';
import { useState } from 'react';
import { PlanCard } from '../PlanCard/PlanCard';
import { SahinSwitch } from '../SahinSwitch/SahinSwitch';

export const plans = [
  {
    id: 'PLAN0000',
    title: 'Başlangıç',
    price: '0',
    description: 'İşletmeniz için ücretsiz QR menu oluşturmanızı sağlar',
    features: [
      'QR menu',
      'Sınırısız Ürün',
      '10 kategori',
      '2 Tema',
      'Subdomain uzantı',
      'Ücretsiz Teknik Destek',
    ],
  },
  {
    id: 'PLAN0001',
    title: 'İleri',
    price: '100',
    description:
      'İşletmeniz için başlangıç seviye QR menu oluşturmanızı sağlar',
    features: [
      'QR menu',
      'Sınırısız Ürün',
      'Sınırısız kategori',
      '4 Tema',
      'Subdomain uzantı',
      'Ücretsiz Teknik Destek',
    ],
  },
  {
    id: 'PLAN0002',
    title: 'Profesyonel',
    price: '150',
    description: 'İşletmeniz için profesyonel QR menu oluşturmanızı sağlar',
    features: [
      'QR menu',
      'Sınırısız Ürün',
      'Garson Çağırma',
      'Sipariş fişi yazdırma',
      'Hesap iste',
      '10 Tema',
      'Dilek öneri şikayet bölümü',
      'Subdomain uzantı',
      'Ücretsiz Teknik Destek',
    ],
  },
  {
    id: 'PLAN0003',
    title: 'Özel',
    price: '300',
    description:
      'İşletmeniz için sizin isterleriniz doğrultusunda QR menu oluşturmanızı sağlar',
    features: [
      'QR menu',
      'Sınırısız Ürün',
      'Garson Çağırma',
      'Sipariş fişi yazdırma',
      'Hesap iste',
      'Masadan sipariş',
      'Rezervasyon yap',
      '20 Tema + 1 özel tema',
      'Dilek öneri şikayet bölümü',
      'Subdomain ve Domain uzantı',
      'Ücretsiz Teknik Destek',
    ],
  },
];

export const Plans = ({ onSelect, switchColorful, sx, ...rest }: any) => {
  const [isYearly, setIsYearly] = useState(true);

  const onSelectHandle = (selected: any, index: number) => {
    onSelect?.(selected, index, isYearly);
  };

  return (
    <Grid xs={12} justifyContent="center" id="pricing" item container {...rest}>
      <Grid xs={12} justifyContent="center" item container>
        <Grid>
          <SahinSwitch
            onChange={(value) => setIsYearly(value)}
            value={isYearly}
            label="AY"
            informativeText="YIL"
            sx={{ width: '250px' }}
            size={1}
            colorful={switchColorful}
          />
        </Grid>
      </Grid>
      <Grid
        xs={12}
        lg={12}
        justifyContent="center"
        columnSpacing={5}
        item
        container
        sx={(theme) => ({
          ...sx,
          [theme.breakpoints.down('md')]: {
            padding: '80px 0',
          },
        })}
        {...rest}
      >
        {plans.map((plan, index) => (
          <Grid key={index} xs={10} lg={12 / plans.length} item>
            <PlanCard
              xs={12}
              plan={plan}
              color={index}
              isYearly={isYearly}
              onSelect={(selected: any) => onSelectHandle(selected, index)}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
