interface DemoDataType {
  key: string;
  label: string;
  url: string;
}

export const demoData: DemoDataType[] = [
  {
    key: '1',
    label: 'Tema 1',
    url: 'http://sah.menukolay.com.tr/',
  },
  {
    key: '2',
    label: 'Tema 2',
    url: 'http://demo2.menukolay.com.tr/',
  },
  {
    key: '3',
    label: 'Tema 3',
    url: 'http://sah.menukolay.com.tr/',
  },
  {
    key: '4',
    label: 'Tema 4',
    url: 'http://demo2.menukolay.com.tr/',
  },
];
