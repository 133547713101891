import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Grid, IconButton, Paper } from '@mui/material';
import { useStyles } from './Collapse.styles';
import { useState } from 'react';

export const Collapse = ({ title, titleRight, children }: any) => {
  const { classes } = useStyles();
  const [isShown, setShow] = useState<boolean>(false);

  return (
    <Grid
      className={classes.card}
      alignContent="flex-start"
      alignItems="flex-start"
      spacing={2}
      component={Paper}
      item
      container
    >
      <Grid item container>
        <Grid xs={10} alignItems="center" item container>
          {title}
        </Grid>
        <Grid
          xs={2}
          alignItems="center"
          justifyContent="flex-end"
          item
          container
        >
          {titleRight}
          <IconButton onClick={() => setShow((pS) => !pS)}>
            {!isShown ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
          </IconButton>
        </Grid>
      </Grid>
      <Grid
        sx={{
          gab: 30,
          overflow: 'hidden',
          ...(isShown
            ? {
                height: '550px',
                opacity: 1,
                paddingTop: 3,
              }
            : {
                opacity: 0,
                height: 0,
              }),
          transition: '1s all',
        }}
        alignItems="flex-start"
        container
      >
        {children}
      </Grid>
    </Grid>
  );
};
