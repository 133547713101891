export enum AdminTablePaths {
  BRAND = 'brand',
  REGISTER = 'register',
  LOGIN = 'login',
  MENU = 'menu',
  USER = 'user',
  SETTING = 'setting',
  PAYMENT = 'payment',
  CONTACT = 'contact',
  DAILY_ORDER = 'order/dailyOrder',
  DAILY_PRICE = 'order/dailyPrice',
  NUMBER_OF_PRODUCT_SALES = 'order/numberOfProductSales',
  FORGOT_PASSWORD = 'forgotPassword',
  RESET_PASSWORD = 'resetPassword',
}
