import { Grid } from '@mui/material';
import { ProjectRouteNames } from 'common/helper/ProjectRouteNames';
import { welcomeRoutes } from 'common/utils/routes';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const hiddenOut = [
  ProjectRouteNames.ABOUT,
  ProjectRouteNames.PRIVACY_POLICY,
  ProjectRouteNames.DELIVERY_RETURN,
  ProjectRouteNames.SALES_CONTRACT,
  ProjectRouteNames.SOCIAL_MEDIA,
  ProjectRouteNames.CONTACT,
];

export const WelcomePage = () => {
  const location = useLocation();
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    if (!location) return;
    const isHide = hiddenOut.includes(
      location.pathname.slice(1) as ProjectRouteNames,
    );
    setHidden(!isHide);
  }, [location]);

  return (
    <Grid
      sx={(theme) => ({
        minHeight: '100vh',
        background: theme.palette.background.default,
        ...(hidden
          ? {
              overflow: 'hidden',
            }
          : undefined),
      })}
      container
    >
      {welcomeRoutes}
    </Grid>
  );
};
