import { Grid, Paper } from '@mui/material';
import { FailedLoad } from 'common/components/FailedLoad/FailedLoad';
import { ListWrapper } from 'common/components/ListWrapper/ListWrapper';
import { Loading } from 'common/components/Loading/Loading';
import { useAppSelector } from 'common/context/hooks';
import { useFetch } from 'common/hooks/useFetchSwr';
import { AdminTablePaths } from 'common/model/AdminTablePaths';
import { datesBetween } from 'common/utils/helper';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivePlanBrandCard } from '../ActivePlanBrandCard/ActivePlanBrandCard';
import { ActivePlanCard } from '../ActivePlanCard/ActivePlanCard';

const findActivePlan = (data: any) => {
  const sorted = data?.sort((a: any, b: any) => {
    return (
      datesBetween(new Date(), b.createdAt, 'month') -
      datesBetween(new Date(), a.createdAt, 'month')
    );
  });

  const mapped = sorted?.map((item: any) => ({
    ...item,
    usingStatus:
      datesBetween(new Date(), item.createdAt, 'month') > 11
        ? 'EXPIRED'
        : 'ACTIVED',
  }));
  const filtered = mapped?.filter(
    (item: any) => item.usingStatus === 'ACTIVED',
  );
  if (!filtered?.lenght && sorted?.lenght) {
    return sorted[0];
  }
  return filtered?.[0];
};

const COMPONENT_TABLE_PATH = AdminTablePaths.PAYMENT;

export const ActivePlan = () => {
  const { t } = useTranslation();
  const brand = useAppSelector((state) => state.common.brand);
  const { data, error, isLoading } = useFetch<any>(COMPONENT_TABLE_PATH);
  const currentPlan = useMemo(() => findActivePlan(data), [data]);

  if (error) return <FailedLoad />;
  if (isLoading) return <Loading />;
  return (
    <ListWrapper title={t('account.accountManagement')}>
      <Grid
        justifyContent="space-between"
        alignContent="start"
        spacing={3}
        item
        container
      >
        <Grid xs={7} item container>
          <Grid component={Paper} item container>
            {data === null && (
              <ActivePlanCard
                key="no-package"
                data={{
                  usingStatus: 'FREE',
                  createdAt: brand?.createdAt,
                  updatedAt: brand?.createdAt,
                  title: '',
                  content: '',
                  quantity: 1,
                  plan: {
                    title: 'KAPALI',
                  },
                }}
              />
            )}
            {currentPlan && (
              <ActivePlanCard key={currentPlan.id} data={currentPlan} />
            )}
          </Grid>
        </Grid>
        <Grid xs={5} item container>
          <Grid component={Paper} item container>
            <ActivePlanBrandCard />
          </Grid>
        </Grid>
      </Grid>
    </ListWrapper>
  );
};
