import { Box, Grid, Paper, Typography } from '@mui/material';
import { SahinButton } from '../SahinButton/SahinButton';
import { useStyles } from './PlanCard.styles';

export const PlanCard = ({
  plan,
  color,
  onSelect,
  isClosedHoverEffect = false,
  isYearly = false,
  xs = 3,
}: any) => {
  const { classes, cx } = useStyles();

  return (
    <Grid
      xs={xs}
      component={Paper}
      justifyContent="start"
      flexDirection="column"
      className={cx({
        [classes.planCard]: true,
        [classes.cardHover]: !plan.disabled && !isClosedHoverEffect,
        [classes.disabled]: plan.disabled,
      })}
      elevation={3}
      item
      container
    >
      <div>
        <Typography className={classes.planTitle}>{plan.title}</Typography>
        <Typography className={classes.planDescription}>
          {plan.description}
        </Typography>
      </div>
      {isYearly && !plan.disabled && plan.price !== '0' && (
        <Typography className={classes.badge}>{'%10 indirim'}</Typography>
      )}
      {plan.price !== '0' ? (
        <Typography className={classes.planPrice}>
          {plan.price - (isYearly ? plan.price / 10 : 0)} TL
          <Box sx={{ mr: 3, display: 'inline-block' }} />
          <small>/ AY</small>
        </Typography>
      ) : (
        <Typography color="#1cab81" fontSize={22}>
          ÜCRETSİZ
          <Box sx={{ mr: 3, display: 'inline-block' }} />
          <small></small>
        </Typography>
      )}
      <Typography>
        {plan.features.map((feature: string, index: number) => (
          <Typography sx={{ margin: 2 }} fontSize="0.9em" key={index}>
            ✓ {feature}
          </Typography>
        ))}
      </Typography>
      <Grid
        alignItems="flex-end"
        justifyContent="center"
        sx={{ flex: 1 }}
        item
        container
      >
        {!plan.disabled && onSelect && (
          <SahinButton
            onClick={() => !plan.disabled && onSelect(plan)}
            variant="outlined"
            color="inherit"
            fullWidth
          >
            Satın Al
          </SahinButton>
        )}
      </Grid>
    </Grid>
  );
};
