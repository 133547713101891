import { Close } from '@mui/icons-material';
import {
  AppBar,
  Button,
  Dialog,
  Grid,
  IconButton,
  Paper,
  Slide,
  Toolbar,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { sahinAlertDialog } from 'common/components/AlertDialog/AlertDialog';
import { Image } from 'common/components/Image/Image';
import { SahinTextField } from 'common/components/TextField/SahinTextField';
import { postAPI } from 'common/helper/apiHelpers';
import { createLink } from 'common/helper/createRoute';
import { AdminRouteNames } from 'common/model/AdminRouteNames';
import { AdminTablePaths } from 'common/model/AdminTablePaths';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { mutate } from 'swr';
import { useStyles } from './AddNewMenuDialog.styles';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const AddNewMenuDialog = ({ open, onClose }: any) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [selected, setSelected] = useState(0);
  const [name, setName] = useState('');

  const onSubmit = () => {
    if (name.length < 4) {
      sahinAlertDialog({
        message: 'Menü adı en az 4 karakter olabilir',
        type: 'error',
      });
      return;
    }

    postAPI(AdminTablePaths.MENU, { name, theme: selected }).then((res) => {
      if (res.status !== 'error') {
        const link = createLink(
          AdminRouteNames,
          `${AdminRouteNames.MENU}/${res.data.id}`,
        );
        navigate(link);

        onClose();
        mutate(AdminTablePaths.MENU);
      }
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      fullScreen
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar component={Paper} className={classes.appbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={onClose}
          >
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Yeni Menü
          </Typography>
          <Button variant="outlined" color="inherit" onClick={onSubmit}>
            OLUŞTUR
          </Button>
        </Toolbar>
      </AppBar>
      <Step
        selected={selected}
        setSelected={setSelected}
        name={name}
        setName={setName}
      />
      ,
    </Dialog>
  );
};

export const Step = ({ selected, setSelected, name, setName }: any) => {
  const { classes, cx } = useStyles();
  return (
    <>
      <Grid justifyContent="center" container>
        <Grid xs={4} p={4}>
          <SahinTextField
            variant="outlined"
            name="name"
            label="Menü İsmi"
            helperText="Bu alana daha sonra hangi temayı kullandığını hatırlayacağın bir isim girmeni tavsiye ederiz 😉"
            value={name}
            onChange={(event) => setName(event.target.value)}
            placeholder="Menüye bir isim ver"
            fullWidth
          />
        </Grid>
      </Grid>
      <Typography textAlign="center" pt={2} fontSize={20} color="inherit">
        Tema Seç
      </Typography>
      <Typography textAlign="center" fontSize={16} color="secondary">
        Seçtiğiniz temaya göre veri girişi sağlanacaktır
      </Typography>
      <Grid p={4} justifyContent="center" container>
        {[0, 1, 2, 3].map((item, index) => (
          <Grid
            key={item}
            xs={2}
            p={2}
            mx={2}
            className={cx({
              [classes.image]: true,
              [classes.selected]: item === selected,
            })}
            onClick={() => setSelected(item)}
            item
            container
          >
            <Grid justifyContent="center" container>
              <Typography color="inherit" textTransform="uppercase" pb={1}>
                Tema {item + 1}
              </Typography>
            </Grid>
            <Image
              src={`/theme-snapshots/${item}.png`}
              width="100%"
              aspectRatio="1/1"
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
