import { Add, RemoveCircle } from '@mui/icons-material';
import { Grid, IconButton, Typography } from '@mui/material';
import { ArrangementButtons } from 'common/components/ArrangementButtons/ArrangementButtons';
import { Collapse } from 'common/components/Collapse/Collapse';
import { sahinConfirmDialog } from 'common/components/ConfirmDialog/ConfirmDialog';
import { SahinButton } from 'common/components/SahinButton/SahinButton';
import { SelectFile } from 'common/components/SelectFile/SelectFile';
import { SahinTextField } from 'common/components/TextField/SahinTextField';
import { MenuListItem } from 'menu/models/Menu';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './MenuForm.styles';
import { menuProductItemDefault } from './MenuForm.types';
import { ProductItem } from './ProductItem';

interface MenuFormProps {
  index: number;
  data: MenuListItem;
  themeSettings: {
    fieldImage: boolean;
    productImage: boolean;
  };
  deleteHandle: () => void;
  onChange: () => void;
  handleMenuAct: (type: 'up' | 'down', index: number) => () => void;
}

export const MenuForm = ({
  deleteHandle,
  index,
  data,
  themeSettings,
  handleMenuAct,
  onChange,
}: MenuFormProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const deleteProduct = (index: number) => () => {
    sahinConfirmDialog({
      title: t('product.delete.title'),
      message: t('product.delete.description'),
      onConfirm: () => {
        data.products = data.products.filter(
          (_, itemIndex) => !(itemIndex === index),
        );
        onChange();
      },
    });
  };

  const handleMenuProductAct = useCallback(
    (type: 'up' | 'down') => (index: number) => () => {
      const newIndex: number = type === 'up' ? index - 1 : index + 1;

      const isFirstItem = index === 0 && type === 'up';
      const isLastItem = index === data.products.length - 1 && type === 'down';
      if (isFirstItem || isLastItem) return;

      const temp = data.products[index];
      data.products[index] = data.products[newIndex];
      data.products[newIndex] = temp;
      onChange();
    },
    [data.products, onChange],
  );

  const title = (
    <>
      <Grid xs={1} alignItems="center" item container>
        <Typography fontSize={34}> #{index + 1}</Typography>
      </Grid>
      <Grid
        xs={11}
        rowSpacing={4}
        justifyContent="space-between"
        item
        container
      >
        <Grid xs={7} alignItems="center" item container>
          <SahinTextField
            label={t('product.partTitle.value')}
            placeholder={t('product.partTitle.placeholder')}
            value={data.name}
            onChange={({ target: { value } }) => {
              data.name = value;
              onChange();
            }}
            fullWidth
          />
        </Grid>
        <Grid xs={5} alignItems="center" item container>
          {themeSettings.fieldImage && (
            <SelectFile
              previewValue={data.image ? [data.image] : undefined}
              onChange={(images) => (data.image = images[0])}
              imageProps={{
                height: 60,
                width: 60,
                objectFit: 'contain',
                aspectRatio: '1/1',
              }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );

  const titleRight = (
    <IconButton onClick={deleteHandle}>
      <RemoveCircle />
    </IconButton>
  );

  return (
    <Grid container>
      <Grid item xs={0.5}>
        <ArrangementButtons
          handleUp={handleMenuAct('up', index)}
          handleDown={handleMenuAct('down', index)}
        />
      </Grid>
      <Grid item xs={11.5}>
        <Collapse title={title} titleRight={titleRight}>
          <Grid
            className={classes.card}
            rowSpacing={1}
            xs={12}
            alignItems="flex-start"
            item
            container
          >
            {data.products?.map((item, index) => (
              <ProductItem
                key={item.id}
                menuProduct={item}
                onChange={onChange}
                deleteProduct={deleteProduct(index)}
                index={index}
                isImage={themeSettings.productImage}
                handleUpProduct={handleMenuProductAct('up')}
                handleDownProduct={handleMenuProductAct('down')}
              />
            ))}
            {(data.products?.length || 0) <= 6 && (
              <Grid justifyContent="center" item container>
                <SahinButton
                  onClick={() => {
                    data.products = [
                      ...(data.products || []),
                      menuProductItemDefault(),
                    ];
                    onChange();
                  }}
                  sx={(theme) => ({ padding: theme.spacing(1, 20) })}
                  startIcon={<Add />}
                  variant="outlined"
                >
                  {t('product.newProduct')}
                </SahinButton>
              </Grid>
            )}
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
};
