import { Grid } from '@mui/material';
import { SahinMaskField } from 'common/components/MaskField/SahinMaskField';
import { SahinTextField } from 'common/components/TextField/SahinTextField';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Setting } from 'settings/models/Settings';
import { SettingsDividerTitle } from './SettingsDividerTitle';

export const ContactForm = ({ register }: any) => {
  const { t } = useTranslation();

  return (
    <>
      {/* İLETİŞİM */}
      <SettingsDividerTitle text={t('settings.contact')} />
      {brandContactForm.map((item) => {
        const SelectedInput = item.component;
        return (
          <Grid key={item.name} xs={12} item>
            <SelectedInput
              {...item?.props}
              {...register(`contact.${item.name}` as keyof Setting)}
              label={
                i18next.exists(`form.${item.name}.value`)
                  ? t(`form.${item.name}.value`)
                  : t(`socialMediaForm.${item.name}.value`)
              }
              placeholder={
                i18next.exists(`form.${item.name}.placeholder`)
                  ? t(`form.${item.name}.placeholder`)
                  : t(`socialMediaForm.${item.name}.placeholder`)
              }
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>
        );
      })}
      {/* SOSYAL MEDYA */}
      <SettingsDividerTitle text={t('settings.socialMedia')} />
      {brandSocialMediaForm.map((item) => (
        <Grid key={item} xs={12} item>
          <SahinTextField
            {...register(`socialMedia.${item}` as keyof Setting)}
            label={t(`socialMediaForm.${item}.value`)}
            placeholder={t(`socialMediaForm.${item}.placeholder`)}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        </Grid>
      ))}
    </>
  );
};

const brandContactForm = [
  { name: 'email', component: SahinTextField },
  { name: 'phone', component: SahinMaskField },
  {
    name: 'whatsapp',
    component: SahinMaskField,
    props: { label: 'Canlı Sipariş Whatsapp Numarası' },
  },
  { name: 'address', component: SahinTextField, props: {} },
];

const brandSocialMediaForm = ['facebook', 'twitter', 'instagram'];
