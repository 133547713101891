import {
  DesktopWindowsOutlined,
  PhoneIphoneOutlined,
  TabletMacOutlined,
} from '@mui/icons-material';
import { AppBar, Grid, IconButton, Toolbar } from '@mui/material';
import { SahinDropdown } from 'common/components/Dropdown/SahinDropdown';
import { SahinButton } from 'common/components/SahinButton/SahinButton';
import { ProjectRouteNames } from 'common/helper/ProjectRouteNames';
import { createLink } from 'common/helper/createRoute';
import { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import { demoData } from '../ResponsivePage/ResponsivePag.types';

interface DemoHeaderProps {
  setSelected: Dispatch<
    SetStateAction<{
      key: string;
      label: string;
      url: string;
    }>
  >;
  setSize: Dispatch<SetStateAction<string>>;
}

export const DemoHeader = ({ setSelected, setSize }: DemoHeaderProps) => {
  return (
    <AppBar
      sx={{
        // background: 'linear-gradient(180deg, #C7C7C7 0%, #808080 50%)',
        background: 'linear-gradient(0deg, #764ba2 30%, #1789ef 100%)',
      }}
      position="static"
    >
      <Toolbar>
        <Grid justifyContent="space-between" container>
          <Grid lg={2} md={12} item container>
            <Grid alignItems="center" component={Link} to="/" item container>
              <img
                src="/logo-white.png"
                style={{ maxWidth: '100%', maxHeight: '30px' }}
                alt="MENU KOLAY"
              />
            </Grid>
          </Grid>
          <Grid xs={7} lg={6} alignItems="center" item container>
            <Grid
              xs={7}
              justifyContent="center"
              alignItems="center"
              item
              container
            >
              <IconButton
                onClick={() => setSize('pc')}
                size="large"
                color="inherit"
              >
                <DesktopWindowsOutlined />
              </IconButton>
              <IconButton
                onClick={() => setSize('pad')}
                size="large"
                color="inherit"
              >
                <TabletMacOutlined fontSize="small" />
              </IconButton>
              <IconButton
                onClick={() => setSize('phone')}
                size="large"
                color="inherit"
              >
                <PhoneIphoneOutlined fontSize="small" />
              </IconButton>
            </Grid>
            <Grid
              xs={5}
              mt={1}
              sx={{
                background: '#EEEEEE4B',
                pt: 1,
                pb: 0,
                px: 0.5,
                mb: 0,
                mt: -0.1,
                borderRadius: 1,
              }}
              item
              container
            >
              <SahinDropdown
                label="Title"
                defaultValue="1"
                size="small"
                data={demoData}
                onChange={(value) => {
                  const currentValue = value.target.value;
                  const found = demoData.find(
                    (item) => item.key === currentValue,
                  );
                  found && setSelected(found);
                }}
              />
            </Grid>
          </Grid>
          <Grid
            xs={6}
            lg={3}
            alignItems="center"
            justifyContent="end"
            item
            container
          >
            <SahinButton
              component={Link}
              to={createLink(ProjectRouteNames, ProjectRouteNames.REGISTER)}
              variant="outlined"
              color="inherit"
            >
              HEMEN KULLANMAYA BAŞLA!
            </SahinButton>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
