import { Button } from '@mui/material';
import clsx from 'clsx';
import { LoadingIcon } from '../Loading/Loadingıcon';
import { useStyles } from './SahinButton.style';
import { SahinButtonProps } from './SahinButton.types';

export const SahinButton = (props: SahinButtonProps) => {
  const {
    children,
    className,
    loading,
    soft,
    text,
    variant = 'contained',
    ...rest
  } = props;
  const classes = useStyles();

  const buttonClasses = clsx(className, {
    [classes.text]: text,
    [classes.soft]: soft,
  });

  return (
    <Button variant={variant} className={buttonClasses} {...rest}>
      {loading && <LoadingIcon size={25} />}
      {!loading && children}
    </Button>
  );
};
