import { generateID } from 'common/utils/helper';
import { MenuListItem, MenuProduct } from 'menu/models/Menu';
export interface FormRefResult {
  getValue: () => any;
}
export interface HakimComponentRef {
  getValue: () => any;
}

export const menuItemDefault = (): MenuListItem => ({
  id: generateID(),
  name: '',
  image: '',
  products: [],
});

export const menuProductItemDefault = (): MenuProduct => ({
  id: generateID(),
  name: '',
  price: undefined,
  description: '',
  order: undefined,
});

export const getValuesCall = (ref: any) => {
  return Object.keys(ref)
    .filter((key) => ref[key])
    .map((key) => ref[key]?.getValue());
};

export const menuConverter = (data: MenuListItem[]): MenuListItem[] => {
  try {
    return data.map((item) => ({
      ...item,
      id: item.id ?? generateID(),
      menu: item.products.map((product) => ({
        ...product,
        id: product.id ?? generateID(),
      })),
    }));
  } catch {
    return [];
  }
};
