import { accountRoute } from 'account/common/helper/AccountRoute';
import { createRoute } from 'common/helper/createRoute';
import { AdminRouteNames } from 'common/model/AdminRouteNames';
import { CorporateIdentityList } from 'corporateIdentity/List/CorporateIdentityList';
import { MenuFormList } from 'menu/Form/MenuFormList';
import { MenuList } from 'menu/List/MenuList';
import { Navigate } from 'react-router-dom';
import { Settings } from 'settings/Form/SettingsForm';

export const adminRoute = createRoute([
  {
    path: `/${AdminRouteNames.MENU}`,
    element: <MenuList />,
  },
  {
    path: `/${AdminRouteNames.MENU}/:id`,
    element: <MenuFormList />,
  },
  {
    path: `/${AdminRouteNames.SETTING}`,
    element: <Settings />,
  },
  {
    path: `/${AdminRouteNames.CORPORATEL_IDENTITY}`,
    element: <CorporateIdentityList />,
  },
  {
    path: `/${AdminRouteNames.ACCOUNT}/*`,
    element: accountRoute,
  },
  {
    path: '*',
    element: <Navigate to={AdminRouteNames.HOME} replace={true} />,
  },
]);
