import { Remove } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';
import { ArrangementButtons } from 'common/components/ArrangementButtons/ArrangementButtons';
import { SelectFile } from 'common/components/SelectFile/SelectFile';
import { SahinTextField } from 'common/components/TextField/SahinTextField';
import { MenuProduct } from 'menu/models/Menu';
import { useTranslation } from 'react-i18next';

interface ProductItemProps {
  index: number;
  isImage: boolean;
  menuProduct: MenuProduct;
  onChange: () => void;
  deleteProduct: () => void;
  handleUpProduct: (index: number) => () => void;
  handleDownProduct: (index: number) => () => void;
}

export const ProductItem = ({
  index,
  isImage,
  menuProduct,
  deleteProduct,
  handleUpProduct,
  handleDownProduct,
  onChange,
}: ProductItemProps) => {
  const { t } = useTranslation();

  const changeField = (key: string, value: string) => {
    (menuProduct as any)[key] = value;
    onChange();
  };

  return (
    <Grid columnSpacing={2} item container>
      {isImage && (
        <Grid
          sx={{
            width: 80,
          }}
          alignItems="center"
          justifyContent="center"
          item
          container
        >
          <SelectFile
            previewValue={menuProduct.image ? [menuProduct.image] : undefined}
            onChange={(images: string[]) => {
              changeField('image', images[0]);
            }}
            imageProps={{
              height: 60,
              width: 60,
              objectFit: 'contain',
              aspectRatio: '1/1',
            }}
          />
        </Grid>
      )}
      <Grid xs={0.8} sx={{ color: '#333' }} item p={0}>
        <ArrangementButtons
          fontSize="small"
          handleUp={handleUpProduct(index)}
          handleDown={handleDownProduct(index)}
        />
      </Grid>
      <Grid xs={4} alignItems="center" item container>
        <SahinTextField
          label={t('form.notHumanName.value')}
          placeholder={t('form.notHumanName.placeholder')}
          value={menuProduct.name}
          onChange={({ target: { value } }) => changeField('name', value)}
          size="small"
          fullWidth
        />
      </Grid>
      <Grid flex={1} alignItems="center" item container>
        <SahinTextField
          label={t('form.description.value')}
          placeholder={t('form.description.placeholder')}
          value={menuProduct.description}
          onChange={({ target: { value } }) =>
            changeField('description', value)
          }
          size="small"
          fullWidth
        />
      </Grid>
      <Grid xs={1.2} alignItems="center" item container>
        <SahinTextField
          label={t('form.price.value')}
          placeholder={t('form.price.placeholder')}
          value={menuProduct.price}
          onChange={({ target: { value } }) => changeField('price', value)}
          size="small"
          fullWidth
        />
      </Grid>
      <Grid xs={1} alignItems="center" item container>
        <IconButton size="small" onClick={deleteProduct}>
          <Remove />
        </IconButton>
      </Grid>
    </Grid>
  );
};
