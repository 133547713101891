export enum ProjectRouteNames {
  PREFIX = '/',
  HOME_PAGE = '*',
  LANDING_PAGE = '/',
  SURVEY = 'survey',
  ADMIN = 'sy/yonetim',
  COMMON = 'common',
  LOGIN = 'login',
  DEMO = 'demo',
  REGISTER = 'register',
  RESET_PASSWORD = 'reset-password',
  ABOUT = 'about',
  SSS = 'sss',
  SUPPORT = 'support',
  CONTACT = 'contact',
  PRIVACY_POLICY = 'privacy-policy',
  DELIVERY_RETURN = 'delivery-return',
  SALES_CONTRACT = 'sales-contract',
  SOCIAL_MEDIA = 'social-media',
  WHY_RK = 'why-rk',
  PAYMENT_ERROR = 'sy/yonetim/account/payment/error',
  PAYMENT_SUCCESS = 'sy/yonetim/account/payment/success',
}
