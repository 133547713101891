import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';

export const ArrangementButtons = ({
  handleUp,
  handleDown,
  fontSize = 'medium',
}: {
  handleUp: () => void;
  handleDown: () => void;
  fontSize?: 'large' | 'medium' | 'small';
}) => {
  return (
    <Grid direction="column" alignItems="center" item container>
      <IconButton size="small" onClick={handleUp}>
        <ArrowUpward fontSize={fontSize} />
      </IconButton>
      <IconButton size="small" onClick={handleDown}>
        <ArrowDownward fontSize={fontSize} />
      </IconButton>
    </Grid>
  );
};
