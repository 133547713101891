import { Grid, Typography } from '@mui/material';
import restaurantMenuDemo from 'assets/img/restorant-menu-demo.png';
import { useStyles } from './Landing.styles';

export const FeaturesImages = () => {
  const { classes } = useStyles();
  return (
    <Grid
      item
      xs={12}
      container
      justifyContent="center"
      alignContent="center"
      className={classes.floor3}
      id="use-cases"
    >
      <Grid
        xs={12}
        container
        justifyContent="space-around"
        alignContent="center"
        item
      >
        <Grid
          sx={{ display: { xs: 'none', md: 'flex' } }}
          lg={6}
          container
          justifyContent="end"
          item
        >
          <img
            src={restaurantMenuDemo}
            alt="Sahin Yazilim"
            style={{
              width: '100%',
              borderRadius: '10px',
            }}
          />
        </Grid>
        <Grid
          xs={12}
          lg={5}
          container
          justifyContent="start"
          flexDirection="column"
          item
        >
          <Typography variant="h4" className={classes.useCasesTitle}>
            QR Kod Sistemi
          </Typography>
          <Typography variant="body2" className={classes.useCasesInfo}>
            Sistemimiz tarafından oluşturulan QR kod ile restorantınızın
            menüsünü müşterilerinize online olarak sunabilirsiniz.
          </Typography>
          <Typography variant="body2" className={classes.useCasesInfo}>
            <ul>
              <li>Otomatik QR kod oluşturma</li>
              <li>Her yerden erişilen çevrimiçi menu</li>
              <li>Sade anlaşılır tasarım</li>
              <li>Özelleştirilebilir esnek sistem</li>
            </ul>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
