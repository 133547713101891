import { Grid, Paper, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useStyles } from './ListWrapper.styles';

interface ListWrapperProps {
  title: string;
  footer?: ReactNode;
  children: ReactNode;
  infoComponent?: ReactNode;
}

export const ListWrapper = ({
  title,
  footer,
  children,
  infoComponent,
}: ListWrapperProps) => {
  const { classes } = useStyles();

  return (
    <Grid
      className={classes.root}
      direction="column"
      alignContent="flex-start"
      alignItems="flex-start"
      item
      container
    >
      <Grid
        className={classes.title}
        component={Paper}
        alignItems="center"
        justifyContent="space-between"
        item
        container
      >
        <Grid item>
          <Typography component="h1" fontSize={30}>
            {title}
          </Typography>
        </Grid>
        <Grid item>{infoComponent}</Grid>
      </Grid>
      <Grid className={classes.contentWrap} item container>
        <Grid
          className={classes.content}
          alignContent="flex-start"
          alignItems="flex-start"
          item
          container
        >
          {children}
        </Grid>
      </Grid>
      {footer && (
        <Grid
          className={classes.footer}
          justifyContent="space-around"
          alignItems="center"
          item
          container
        >
          {footer}
        </Grid>
      )}
    </Grid>
  );
};
