import { Grid } from '@mui/material';
import { SahinTextField } from 'common/components/TextField/SahinTextField';
import { useTranslation } from 'react-i18next';
import { Setting } from 'settings/models/Settings';
import { LogoForm } from './LogoForm';
import { SettingSwitchField } from './SettingSwitchField';
import { SettingsDividerTitle } from './SettingsDividerTitle';

export const BrandForm = ({
  register,
  errors,
  control,
  setting,
  setValue,
  handleOnSubmit,
}: any) => {
  const { t } = useTranslation();

  return (
    <>
      <SettingsDividerTitle text={t('settings.siteInformation')} />
      {/* TİTLE */}
      <Grid xs={12} item>
        <SahinTextField
          {...register(`store.title` as keyof Setting)}
          error={errors?.store?.title?.message}
          label={t('form.title.value')}
          placeholder={t('form.title.placeholder')}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      </Grid>
      <LogoForm
        setting={setting}
        register={register}
        errors={errors}
        setValue={setValue}
        handleOnSubmit={handleOnSubmit}
      />
      <SettingsDividerTitle text={t('general')} />
      {generalSettingsForm.map((item) => (
        <>
          <SettingSwitchField control={control} {...item} />
          <SettingsDividerTitle />
        </>
      ))}
    </>
  );
};

const generalSettingsForm = [
  { name: 'store.isMenu', label: 'Menü Kullanıma Açık' },
];
