import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    height: '100%',
  },
  dataTable: {
    flex: 1,
    borderRadius: '0!important',
    border: 'none!important',
  },
});
