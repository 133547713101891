import { Divider, Grid } from '@mui/material';

export const SettingsDividerTitle = ({ text }: any) => {
  return (
    <Grid xs={12} sx={{ pt: 3 }} item>
      <Divider
        textAlign="center"
        sx={{
          fontSize: 16,
        }}
        light
      >
        {text}
      </Divider>
    </Grid>
  );
};
