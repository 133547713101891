import { GlobalStyles } from 'tss-react';

export const GlobalCss = () => {
  return (
    <GlobalStyles
      styles={{
        body: {
          margin: 0,
          padding: 0,
          fontSize: '12px',
          fontWeight: 400,
          fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
        },
        svg: {
          display: 'inline-block',
          float: 'left',
        },
        '.height100': {
          height: '100%',
        },
        '.min-height100': {
          minHeight: '100%',
        },
        '.of-hidden': {
          overflow: 'hidden',
        },
        '.of-auto': {
          overflow: 'auto',
        },
        'input:-webkit-autofill': {
          WebkitBoxShadow: '0 0 0 100px #transparent inset !important',
        },
        '& ::-webkit-scrollbar': {
          width: '12px',
        },
        '& ::-webkit-scrollbar-track': {
          backgroundColor: '#fff',
        },
        '& ::-webkit-scrollbar-thumb': {
          backgroundColor: '#6099ef',
        },
        '& .admin ::-webkit-scrollbar': {
          width: '14px',
        },
        '& .admin ::-webkit-scrollbar-track': {
          backgroundColor: '#eee',
        },
        '& .admin ::-webkit-scrollbar-thumb': {
          backgroundColor: '#888',
        },
      }}
    />
  );
};
