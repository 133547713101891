import { AddPhotoAlternate, CloseOutlined } from '@mui/icons-material';
import { Grid, Tooltip } from '@mui/material';
import { uploadAPI } from 'common/helper/apiHelpers';
import { AdminTablePaths } from 'common/model/AdminTablePaths';
import { getCdnImageUrl } from 'common/utils/image';
import { ChangeEvent, useCallback, useRef, useState } from 'react';
import { Image } from '../Image/Image';
import { SahinButton } from '../SahinButton/SahinButton';
import { useStyles } from './SelectFile.styles';
import { SelectFileProps } from './SelectFile.types';

export const SelectFile = ({
  value,
  previewValue,
  path = AdminTablePaths.BRAND,
  onChange,
  containerProps,
  imageProps,
  multiple,
}: SelectFileProps) => {
  const { classes, cx } = useStyles();
  const [preview, setPreview] = useState<string[]>(
    Array.isArray(previewValue)
      ? previewValue
      : previewValue
      ? [previewValue]
      : [],
  );
  const [readyImage, setReadyImage] = useState<string[]>([]);
  const [progress, setProgress] = useState<number>(0);
  const uploadedIndex = useRef<number>(0);
  const inputRef = useRef<HTMLInputElement>(null);

  const openFileSelectWindow = () => {
    inputRef.current?.click();
  };

  const fileUploadHandler = useCallback(
    async (files: FileList, ndx: number): Promise<string[]> => {
      if (!files[ndx] || !files?.length) {
        console.log('file not found');
        return [];
      }
      const uploaded = await uploadAPI(path, files[ndx], (api) => {
        const progress = Math.floor((api.progress || 0) * 100);
        setProgress(progress);
      });
      let uploadedFiles: string[] = [];
      setReadyImage((prevState) => {
        prevState?.splice(0, 1);
        return [...prevState];
      });
      uploaded?.path &&
        setPreview((prevState) => [...prevState, uploaded?.path]);
      if (ndx < files.length - 1) {
        uploadedFiles = await fileUploadHandler(files, ndx + 1);
      }

      return [uploaded?.path, ...uploadedFiles];
    },
    [path],
  );

  const fileUpload = useCallback(
    async (files: FileList) => {
      if (!files?.length) return value;
      const uploadedFileURL = await fileUploadHandler(files, 0);
      uploadedIndex.current = 0;
      setProgress(0);
      onChange?.(uploadedFileURL.map((item) => item));
    },
    [fileUploadHandler, onChange, value],
  );

  const addFile = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      if (!files || !files.length) return;
      const fileUrl = Array.from(files).map((file) =>
        URL.createObjectURL(file),
      );

      setReadyImage(fileUrl);
      setTimeout(() => fileUpload(files), 2000);
    },
    [fileUpload],
  );

  const onDelete = useCallback(
    (index: number) => {
      setPreview((prevState) => {
        prevState.splice(index, 1);
        const newState = [...prevState];
        onChange?.(newState);
        return newState;
      });
    },
    [onChange],
  );

  return (
    <Grid
      spacing={0.5}
      flex={1}
      justifyContent="end"
      item
      container
      {...containerProps}
    >
      {[...readyImage, ...preview]?.map((image, index) => {
        const isUploading = index < readyImage.length;
        return (
          <Grid
            key={index}
            className={cx({
              [classes.imageContainer]: true,
              [classes.selectedContainer]: isUploading,
            })}
            item
          >
            <Image
              className={classes.selectedImage}
              src={isUploading ? image : getCdnImageUrl(image)}
              alt="images"
              progress={progress}
              {...imageProps}
            />
            {!isUploading && (
              <span
                className={classes.deleteIcon}
                onClick={() => onDelete(index)}
              >
                <Tooltip title="Kaldır">
                  <CloseOutlined fontSize="small" />
                </Tooltip>
              </span>
            )}
          </Grid>
        );
      })}
      {(multiple || (preview?.length <= 0 && readyImage?.length <= 0)) && (
        <>
          <input
            ref={inputRef}
            type="file"
            style={{ display: 'none' }}
            onChange={addFile}
            multiple={multiple}
          />
          <SahinButton
            className={classes.uploadButton}
            color="secondary"
            onClick={openFileSelectWindow}
            soft
          >
            <AddPhotoAlternate />
          </SahinButton>
        </>
      )}
    </Grid>
  );
};
