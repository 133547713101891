import { MenuOutlined } from '@mui/icons-material';
import {
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import clsx from 'clsx';
import { OwnerEnv } from 'common/utils/env';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Image } from '../Image/Image';
import { useStyles } from './SahinDrawer.styles';
import { menuList } from './SahinDrawerList';
import { useAppSelector } from 'common/context/hooks';
import { ThemeMode } from 'common/model/ThemeMode';

export const SahinDrawer = ({ children }: any) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const theme = useAppSelector((state) => state.common.theme);
  const [isOpen, setIsOpen] = useState(true);

  return (
    <>
      <Grid
        className={classes.root}
        width={isOpen ? 272 : 55}
        direction="column"
        container
        item
      >
        <Grid
          justifyContent="center"
          alignItems="center"
          sx={{
            p: 2,
            pb: 0,
          }}
          onClick={() => navigate('/')}
          container
        >
          {isOpen && (
            <Grid xs={10}>
              <Image
                justifyContent="center"
                container
                src={
                  theme === ThemeMode.DARK
                    ? '/logo-white.png'
                    : '/logo-black.png'
                }
                alt={OwnerEnv.title}
                aspectRatio="800/80"
                height="100%"
              />
            </Grid>
          )}
          <Grid flex={1} justifyContent="end" container>
            <MenuOutlined
              onClick={() => {
                setIsOpen((prevState) => !prevState);
              }}
            />
          </Grid>
        </Grid>
        <Grid flex={1} className="height100" item container>
          <Drawer
            variant="permanent"
            anchor="left"
            classes={{
              root: classes.drawer,
              paper: classes.paper,
            }}
          >
            <Grid
              direction="column"
              justifyContent="space-between"
              flex={1}
              container
            >
              {menuList({ open: isOpen, theme }).map((menuGroup) => (
                <Grid key={menuGroup.id} item container>
                  <List sx={{ width: '100%' }}>
                    {menuGroup.menu.map((menuItem, index) => (
                      <SahinListItem
                        key={index}
                        menuItem={menuItem}
                        open={isOpen}
                      />
                    ))}
                  </List>
                  <Divider />
                </Grid>
              ))}
            </Grid>
          </Drawer>
        </Grid>
      </Grid>
      <Grid sx={{ height: '100%;' }} flex={1} container item>
        {children}
      </Grid>
    </>
  );
};

const SahinListItem = ({ menuItem, open }: any) => {
  const { icon, link, disabled, badge, label, action } = menuItem;
  const { classes } = useStyles();
  const navigate = useNavigate();

  const Icon = icon;
  return (
    <ListItem
      className={clsx(classes.listItem, !open && classes.listItemClosed)}
      disabled={disabled}
      onClick={() => (link ? navigate(link) : action?.())}
      dense={open}
    >
      <ListItemButton>
        <ListItemIcon className={classes.icon}>
          <Icon />
        </ListItemIcon>
        {open && <ListItemText primary={label} />}
        {open && badge}
      </ListItemButton>
    </ListItem>
  );
};
