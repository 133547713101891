import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  image: {
    cursor: 'pointer',
    border: `5px solid ${theme.palette.background.default}`,
    borderRadius: theme.spacing(1),
    '& img': {
      borderRadius: theme.spacing(1),
    },
  },
  selected: {
    border: `5px solid ${theme.palette.primary.dark}`,
  },
  appbar: {
    borderRadius: 0,
    background: 'black',
    color: '#fff',
  },
}));
