import { Grid, Paper, Tab, Tabs } from '@mui/material';
import { ListWrapper } from 'common/components/ListWrapper/ListWrapper';
import { Loading } from 'common/components/Loading/Loading';
import { SahinButton } from 'common/components/SahinButton/SahinButton';
import { useAppSelector } from 'common/context/hooks';
import { postAPI } from 'common/helper/apiHelpers';
import { AdminTablePaths } from 'common/model/AdminTablePaths';
import { trimAllSpace } from 'common/utils/helper';
import { useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Setting } from 'settings/models/Settings';
import { mutate } from 'swr';
import { SettingFormYup } from './SettingsForm.types';
import { BrandForm } from './components/BrandForm';
import { ContactForm } from './components/ContactForm';

export const COMPONENT_TABLE_PATH = AdminTablePaths.SETTING;
const settingsDefault = {
  store: {
    isOrderOpen: true,
    isGuestOrder: true,
    isMenu: true,
  },
};

export const Settings = () => {
  const settings = useAppSelector((state) => state.common.settings);
  const currentSettings = useMemo(
    () => ({
      ...settingsDefault,
      ...settings,
      store: { ...settingsDefault.store, ...settings?.store },
    }),
    [settings],
  );
  if (!settings) return <Loading />;
  return <SettingsForm setting={currentSettings as Setting} />;
};

const SettingsForm = ({ setting }: { setting: Setting }) => {
  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<Required<Setting>>({
    defaultValues: setting,
    resolver: SettingFormYup,
    mode: 'onBlur',
  });

  const { t } = useTranslation();

  const settingsForm = useRef<HTMLInputElement>(null);
  const [activePage, setActivePage] = useState(0);

  const onSubmit = (data: Required<Setting>) => {
    data.contact.phone = trimAllSpace(data?.contact?.phone);
    data.contact.whatsapp = trimAllSpace(data?.contact?.whatsapp);

    const promise = postAPI(COMPONENT_TABLE_PATH, data);
    promise.then(() => mutate(COMPONENT_TABLE_PATH)).catch(console.log);
  };

  const handleOnSubmit = handleSubmit(onSubmit);
  const formProps = {
    register,
    errors,
    control,
    setting,
    setValue,
    handleOnSubmit,
  };
  return (
    <ListWrapper
      title={t('settings.settings')}
      infoComponent={
        <SahinButton
          sx={{ textTransform: 'uppercase' }}
          onClick={() => settingsForm.current?.click()}
          disabled={!isValid}
        >
          {t('save')}
        </SahinButton>
      }
    >
      <Grid
        rowSpacing={2}
        className="height100"
        direction="column"
        item
        container
      >
        <Grid item container>
          <Grid component={Paper} item container>
            <Tabs value={activePage}>
              {[t('settings.siteInformation'), t('settings.contact')].map(
                (item: any, index: number) => (
                  <Tab
                    key={index}
                    label={item}
                    onClick={() => setActivePage(index)}
                  />
                ),
              )}
            </Tabs>
          </Grid>
        </Grid>
        <Grid flex={1} className="of-hidden" item container>
          <Grid
            component={Paper}
            className="of-auto height100"
            sx={{ p: 5 }}
            item
            container
          >
            <Grid
              onSubmit={handleOnSubmit}
              alignContent="flex-start"
              justifyContent="space-between"
              component="form"
              container
            >
              {[
                <BrandForm key={1} {...formProps} />,
                <ContactForm key={2} {...formProps} />,
              ].map((item, ndx) => (
                <Grid
                  key={ndx}
                  style={{ display: activePage === ndx ? 'flex' : 'none' }}
                  alignContent="flex-start"
                  rowSpacing={3}
                  item
                  container
                >
                  {item}
                </Grid>
              ))}
              <input
                type="submit"
                style={{ display: 'none' }}
                ref={settingsForm}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ListWrapper>
  );
};
