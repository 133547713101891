import { TextField, TextFieldProps } from '@mui/material';
import { forwardRef } from 'react';
import { useStyles } from './SahinTextField.styles';

type CustomProps = Omit<TextFieldProps, 'error'> & {
  error?: any;
  autocomplete?: string;
};

export const SahinTextField = forwardRef<any, CustomProps>((props, ref) => {
  const { className, error, ...rest } = props;
  const { classes, cx } = useStyles();

  return (
    <>
      <TextField
        {...rest}
        error={!!error}
        helperText={error || rest.helperText}
        color={error ? 'error' : undefined}
        ref={ref}
        className={cx(classes.root, className)}
      />
    </>
  );
});
