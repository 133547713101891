import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import { ReactNode, forwardRef, useEffect, useState } from 'react';
import { useStyles } from './SahinDropdown.styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

type SahinDropdownDataType = { key: string; label: string | ReactNode };

type SahinDropdownProps = Omit<SelectProps, 'value' | 'error'> & {
  label: string;
  data: SahinDropdownDataType[];
  value?: string | SahinDropdownDataType;
  error?: string;
  shrink?: boolean;
};

export const SahinDropdown = forwardRef<any, SahinDropdownProps>(
  (props, ref) => {
    const {
      label,
      data,
      onChange,
      value,
      error,
      multiple = false,
      shrink,
      defaultValue,
      ...rest
    } = props;
    const { classes } = useStyles();
    const [valueState, setValueState] = useState<SahinDropdownDataType>();

    useEffect(() => {
      const temp = data?.find((item) => item.key === value);
      setValueState(temp);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const handleChange = (
      event: SelectChangeEvent<any>,
      child: React.ReactNode,
    ) => {
      const {
        target: { value },
      } = event;

      onChange?.(event, child);
      setValueState(value);
    };

    return (
      <FormControl fullWidth>
        <InputLabel id="select-label" shrink={shrink}>
          {label}
        </InputLabel>
        <Select
          fullWidth
          labelId="select-label"
          value={valueState ?? defaultValue ?? ''}
          {...rest}
          label={label}
          onChange={handleChange}
          MenuProps={MenuProps}
          multiple={multiple}
          ref={ref}
        >
          {data?.map((item) => (
            <MenuItem key={item.key} value={item.key}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        <Typography className={classes.error}>{error}</Typography>
      </FormControl>
    );
  },
);
