import { darken } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  root: {
    background: darken(theme.palette.background.paper, 0.3),
    padding: '0 8px!important',
    paddingRight: '20px!important',
    justifyContent: 'space-between',
    '& *': { color: '#eee' },
    '& [class*=disabled]': { opacity: 0.2 },
  },
}));
