import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  imageContainer: {
    position: 'relative',
  },
  selectedContainer: {
    opacity: 0.5,
  },
  selectedImage: {
    borderRadius: 6,
    border: '1px dashed #4e4e4e',
  },
  deleteIcon: {
    position: 'absolute',
    top: 5,
    right: 0,
    background: '#ffffff47',
    cursor: 'pointer',
  },
  uploadContainer: {
    width: 50,
    height: 50,
  },
  uploadButton: {
    border: '3px dashed #4e4e4e',
    width: 50,
    height: 50,
    minWidth: 'unset',
  },
}));
