import { ThemeProvider } from 'common/components/ThemeProvider/ThemeProvider';
import { store } from 'common/context/store';
import { initI18n } from 'common/i18n/I18n';
import { SwrManager } from 'common/managers/SwrManager';
import { mainRoutes } from 'common/utils/routes';
import { SnackbarProvider } from 'notistack';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

initI18n();

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <Provider store={store}>
      <ThemeProvider>
        <BrowserRouter>
          <SnackbarProvider
            maxSnack={5}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            dense
          >
            <SwrManager>{mainRoutes}</SwrManager>
          </SnackbarProvider>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </StrictMode>,
);
