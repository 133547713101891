import BallotIcon from '@mui/icons-material/Ballot';
import { Button } from '@mui/material';
import { SahinDataTable } from 'common/components/DataTable/SahinDataTable';
import { ListWrapper } from 'common/components/ListWrapper/ListWrapper';
import { useAppSelector } from 'common/context/hooks';
import { AdminTablePaths } from 'common/model/AdminTablePaths';
import { Contact } from 'common/model/Contact';
import { Nullable } from 'common/model/Nullable';
import { useCallback, useState } from 'react';
import { ContactForm } from '../Form/ContactForm';
import { contactListColumns } from './ContactList.types';

export const COMPONENT_TABLE_PATH = AdminTablePaths.CONTACT;

export const ContactList = () => {
  const contacts = useAppSelector((state) => state.common.contacts);
  const [selectedItem, setSelected] =
    useState<Nullable<Partial<Contact>>>(null);

  const onAdd = () => {
    setSelected({});
  };

  const onCloseModal = () => {
    setSelected(null);
  };

  const showContact = useCallback((params: any) => {
    const handleSelect = () => {
      setSelected(params.row as Contact);
    };

    return (
      <Button onClick={handleSelect}>
        <BallotIcon />
      </Button>
    );
  }, []);

  return (
    <ListWrapper title="Destek Talepleri">
      <ContactForm
        open={Boolean(selectedItem)}
        contact={selectedItem}
        handleClose={onCloseModal}
      />
      <SahinDataTable
        data={contacts}
        columns={contactListColumns}
        onAdd={onAdd}
        customActionComponent={showContact}
        customActionComponentTitle="Görüntüle"
        actionButtonColumnWidth={150}
      />
    </ListWrapper>
  );
};
