import { Add, LaunchOutlined, Save } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { sahinConfirmDialog } from 'common/components/ConfirmDialog/ConfirmDialog';
import { FailedLoad } from 'common/components/FailedLoad/FailedLoad';
import { ListWrapper } from 'common/components/ListWrapper/ListWrapper';
import { Loading } from 'common/components/Loading/Loading';
import { SahinButton } from 'common/components/SahinButton/SahinButton';
import { useAppSelector } from 'common/context/hooks';
import { updateAPI } from 'common/helper/apiHelpers';
import { useFetch } from 'common/hooks/useFetchSwr';
import { AdminTablePaths } from 'common/model/AdminTablePaths';
import { getBrandDomain } from 'common/utils/helper';
import { MenuForm } from 'menu/Form/MenuForm';
import { menuItemDefault } from 'menu/Form/MenuForm.types';
import { Menu, MenuListItem } from 'menu/models/Menu';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { mutate } from 'swr';

const themeProperties = [
  {
    fieldImage: true,
    productImage: false,
  },
  {
    fieldImage: false,
    productImage: true,
  },
  {
    fieldImage: false,
    productImage: true,
  },
  {
    fieldImage: true,
    productImage: true,
  },
];

export const MenuFormList = () => {
  const { t } = useTranslation();
  const params = useParams();
  const COMPONENT_TABLE_PATH = params['*'] as AdminTablePaths;
  const { data, error, isLoading } = useFetch<Menu>(COMPONENT_TABLE_PATH);
  const brand = useAppSelector((state) => state.common.brand);
  const [menu, setMenu] = useState<MenuListItem[]>([]);
  const url = useMemo(() => getBrandDomain(brand), [brand]);

  useEffect(() => {
    if (data?.menuData) setMenu(data.menuData || []);
  }, [data?.menuData]);

  const onChange = () => setMenu([...menu]);

  const deleteSection = (index: number) => () => {
    sahinConfirmDialog({
      title: 'Silmek istedğinize emin misiniz?',
      message: 'Silmek istedğinize emin misiniz?',
      onConfirm: () => {
        setMenu(
          menu.filter((item, itemIndex) => {
            if (itemIndex === index) {
              return false;
            }
            return true;
          }),
        );
      },
    });
  };

  const saveHandle = () => {
    if (!params.id) return;
    const promise = updateAPI(AdminTablePaths.MENU, params.id, { menu });

    promise.then(() => {
      mutate(COMPONENT_TABLE_PATH);
    });
  };

  const handleMenuAct = useCallback(
    (type: 'up' | 'down', index: number) => () => {
      const newIndex: number = type === 'up' ? index - 1 : index + 1;

      setMenu((prevState) => {
        const isFirstItem = index === 0 && type === 'up';
        const isLastItem = index === prevState.length - 1 && type === 'down';
        if (isFirstItem || isLastItem) {
          return prevState;
        }

        const temp = prevState[index];
        prevState[index] = prevState[newIndex];
        prevState[newIndex] = temp;
        return [...prevState];
      });
    },
    [setMenu],
  );

  const footerButton = (
    <Grid gap={3} justifyContent="center" mt={6} mb={4} item container>
      <SahinButton
        onClick={() => {
          setMenu((pS) => [...pS, menuItemDefault()]);
        }}
        startIcon={<Add />}
        sx={(theme) => ({ padding: theme.spacing(3, 14) })}
        size="large"
        color="inherit"
        variant="outlined"
      >
        {t('menu.newMenuArea')}
      </SahinButton>
    </Grid>
  );

  const headerButtons = (
    <Grid gap={3} item container>
      <SahinButton
        component="a"
        target="_blank"
        href={url}
        color="inherit"
        variant="outlined"
        endIcon={<LaunchOutlined />}
      >
        {t('menu.goMenu')}
      </SahinButton>
      <SahinButton onClick={saveHandle} startIcon={<Save />}>
        {t('save')}
      </SahinButton>
    </Grid>
  );

  if (error) return <FailedLoad />;
  if (isLoading) return <Loading />;
  return (
    <ListWrapper title={t('menu.menu')} infoComponent={headerButtons}>
      <Grid sx={{ gap: 3 }} container>
        {menu.map((item, index) => (
          <MenuForm
            data={item}
            key={item.id}
            index={index}
            handleMenuAct={handleMenuAct}
            deleteHandle={deleteSection(index)}
            themeSettings={themeProperties[data?.theme || 0]}
            onChange={onChange}
          />
        ))}
        {footerButton}
      </Grid>
    </ListWrapper>
  );
};
