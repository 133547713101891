import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((_theme) => ({
  root: {
    flex: 1,
    height: '100%',
    overflow: 'hidden',
  },
  tab: {
    fontWeight: 600,
    margin: _theme.spacing(0, 2),
  },
}));
