import { Grid } from '@mui/material';
import { useState } from 'react';
import { DemoHeader } from '../DemoHeader/DemoHeader';
import { useStyles } from './ResponsivePage.style';
import { demoData } from './ResponsivePag.types';

export const ResponsivePage = () => {
  const { classes, cx } = useStyles();
  const [selected, setSelected] = useState(demoData[0]);
  const [size, setSize] = useState('pc');

  return (
    <Grid
      alignContent="start"
      direction="column"
      bgcolor="#DBE3E3BA"
      item
      container
    >
      <DemoHeader setSelected={setSelected} setSize={setSize} />
      <Grid flex={1} justifyContent="center" item container>
        <iframe
          src={selected.url}
          title="Demo 1"
          className={cx({
            [classes.iframe]: true,
            [classes.phone]: size === 'phone',
            [classes.pad]: size === 'pad',
          })}
        />
      </Grid>
    </Grid>
  );
};
