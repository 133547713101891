import { Chip, Grid, Typography } from '@mui/material';
import { AccountRouteNames } from 'account/common/model/AccountRouteNames';
import { SahinButton } from 'common/components/SahinButton/SahinButton';
import { createLink } from 'common/helper/createRoute';
import {
  dateFormatDayMonthYear,
  dateSum,
  datesBetween,
} from 'common/utils/helper';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './ActivePlanCard.styles';
import { useTranslation } from 'react-i18next';

export const ActivePlanCard = ({ data }: any) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const list: any = [
    {
      titleElement: (
        <Chip
          color={
            data.usingStatus === 'ACTIVED'
              ? 'success'
              : data.usingStatus === 'EXPIRED'
              ? 'error'
              : 'default'
          }
          label={data.usingStatus}
        />
      ),
      content: '',
    },
    {
      titleElement: `${dateFormatDayMonthYear(
        data.createdAt,
      )} / ${dateFormatDayMonthYear(
        data.usingStatus === 'FREE'
          ? dateSum(data.createdAt, 15, 'days')
          : dateSum(
              data.createdAt,
              Number(data.quantity) + (data.refCode ? 2 : 0),
              'months',
            ),
      )}`,
    },
    // {
    //   title: 'Ödenen',
    //   content: data.totalPrice,
    // },
    // {
    //   title: 'İndirim',
    //   content: data.discount,
    // },
  ];

  return (
    <Grid className={classes.card} item container>
      <Grid
        xs={4}
        gap={2}
        className={cx(classes.titleCard, {
          [classes.planCardColor5]: data.usingStatus === 'FREE',
        })}
        direction="column"
        justifyContent="space-between"
        item
        container
      >
        <Grid className={classes.title} item>
          {data.usingStatus === 'FREE'
            ? 'ÜCRETSİZ PLAN'
            : `${data.plan.title} ${t('account.package')}`}
        </Grid>
        <Grid
          className={classes.title}
          justifyContent="space-between"
          alignItems="flex-end"
          item
          container
        >
          {data.usingStatus !== 'FREE' && (
            <Grid>
              {data.quantity} {t('month').toUpperCase()}{' '}
              {data.refCode && <Typography color="secondary">+2 AY</Typography>}
            </Grid>
          )}
          {data.usingStatus !== 'FREE' ? (
            <Grid component={Typography} fontSize={12}>
              {datesBetween(new Date(), data.createdAt, 'month') + 1} /{' '}
              {Number(data.quantity) + (data.refCode ? 2 : 0)}{' '}
              {t('month').toUpperCase()}
            </Grid>
          ) : (
            <Grid component={Typography} fontSize={12}>
              Deneme Sürümü
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        xs={8}
        className={classes.infoCard}
        alignContent="space-between"
        gap={2}
        item
        container
      >
        {list.map((item: any, ndx: number) => (
          <Grid key={ndx} item container>
            <Grid xs={6} alignItems="center" item container>
              {item.titleElement ?? `${item.title} : `}
            </Grid>
            <Grid xs={6} alignItems="center" item container>
              <Typography fontSize={14}>{item.content}</Typography>
            </Grid>
          </Grid>
        ))}
        <Grid justifyContent="end" item container>
          <SahinButton
            onClick={() =>
              navigate(
                createLink(AccountRouteNames, AccountRouteNames.PLAN_SELECT),
              )
            }
            color="primary"
            size="small"
            soft
          >
            YÜKSELT
          </SahinButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
