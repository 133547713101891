import {
  QrCode2,
  RestaurantMenu,
  SupportAgent,
  Translate,
} from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { useStyles } from './Landing.styles';

const features = [
  {
    icon: <Translate sx={{ fontSize: '6em' }} />,
    title: 'Çoklu Dil Desteği',
    content:
      'Çoklu dil desteği ile müşterilerinize kendi dillerinde hizmet verin',
  },
  {
    icon: <QrCode2 sx={{ fontSize: '6em' }} />,
    title: 'QR Kod sistemi',
    content: 'QR kod sistemi ile müşterilerinizi kolayca yönlendirin',
  },
  {
    icon: <RestaurantMenu sx={{ fontSize: '6em' }} />,
    title: 'Çevrimiçi Menü',
    content: 'Menünüzü çevrimiçi olarak müşterilerinize sunun',
  },
  {
    icon: <SupportAgent sx={{ fontSize: '6em' }} />,
    title: 'Süresiz Teknik Destek',
    content: 'Süresiz teknik destek ile her zaman yanınızdayız',
  },
];

export const Features = () => {
  const { classes } = useStyles();

  return (
    <Grid
      item
      xs={12}
      container
      justifyContent="center"
      className={classes.floor6}
      id="features"
    >
      <Typography variant="h4" className={classes.featuresMainTitle}>
        Özellikler
      </Typography>

      <Grid container>
        {features.map((item, index) => (
          <Grid key={item.title} item xs={6}>
            <Grid xs={2} item>
              <Typography textAlign="center">{item.icon}</Typography>
            </Grid>
            <Grid xs={10} item>
              <Typography
                variant="body2"
                className={classes.featuresTitle}
                textAlign="center"
              >
                {item.title}
              </Typography>
              <Typography
                variant="body2"
                className={classes.featuresInfo}
                textAlign="center"
              >
                {item.content}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
