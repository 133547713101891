import { ListWrapper } from 'common/components/ListWrapper/ListWrapper';
import { SahinTabs } from 'common/components/Tab/Tab';
import { useAppSelector } from 'common/context/hooks';
import { getBrandDomain } from 'common/utils/helper';
import { CreateQr } from 'corporateIdentity/components/CreateQr/CreateQr';
import { useMemo } from 'react';

export const CorporateIdentityList = () => {
  const brand = useAppSelector((state) => state.common.brand);

  const title = useMemo(() => getBrandDomain(brand, true), [brand]);
  const url = useMemo(() => getBrandDomain(brand), [brand]);
  const filePage = useMemo(
    () => [
      {
        label: 'QR Oluştur',
        content: <CreateQr url={url} title={title} />,
      },
      // {
      //   label: 'Banner Oluştur',
      //   content: <CreateBanner url={url} title={title} />,
      // },
    ],
    [title, url],
  );

  return (
    <ListWrapper title="QR Editör">
      <SahinTabs data={filePage} />
    </ListWrapper>
  );
};
