import { createTheme } from '@mui/material';

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    secondary: {
      light: '#f5f5f5',
      main: '#ddd',
      dark: '#bbb',
      contrastText: '#333',
    },
    background: {
      default: '#f8f8f8',
      paper: '#fff',
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    secondary: {
      light: '#5e5e5e',
      main: '#333',
      dark: '#1e1e1e',
      contrastText: '#ddd',
    },
    background: {
      default: '#333',
      paper: '#1e1e1e',
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
  },
});
