import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((_theme) => ({
  iframe: {
    width: '100%',
    height: '100%',
    transition: '500ms all',
  },
  phone: {
    width: 400,
  },
  pad: {
    width: 770,
  },
}));
