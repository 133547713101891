import { Grid, Paper, Tab, Tabs } from '@mui/material';
import { Image } from 'common/components/Image/Image';
import { SelectFile } from 'common/components/SelectFile/SelectFile';
import { SahinTextField } from 'common/components/TextField/SahinTextField';
import { getCdnImageUrl } from 'common/utils/image';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const LogoForm = ({
  setting,
  register,
  errors,
  setValue,
  handleOnSubmit,
}: any) => {
  const { t } = useTranslation();

  const [logoState, setLogoState] = useState(0);

  const onChangeLogo = (images: string[]) => {
    if (!images?.length) return;
    const [image] = images;
    setValue('store.logo', getCdnImageUrl(image));
    handleOnSubmit();
  };

  return (
    <>
      <Grid xs={12} justifyContent="space-between" item container>
        <Grid xs={12} item>
          <Grid component={Paper} container>
            <Tabs
              value={logoState}
              onChange={(_, value) => {
                setLogoState(value);
              }}
            >
              <Tab label={t('link')} />
              <Tab label={t('upload')} />
            </Tabs>
          </Grid>
        </Grid>
        <Grid
          xs={12}
          sx={{ display: logoState === 1 ? 'none' : 'unset', mt: 4 }}
          item
          container
        >
          <SahinTextField
            {...register('store.logo')}
            error={errors?.store?.logo?.message}
            label="Logo"
            placeholder="Enter logo"
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
          <Grid xs={5.5} mt={2} item>
            {setting?.store?.logo && (
              <Image src={setting.store.logo} height={80} />
            )}
          </Grid>
        </Grid>
        <Grid
          xs={12}
          sx={{ display: logoState === 0 ? 'none' : 'unset' }}
          mt={4}
          item
          container
        >
          <SelectFile
            onChange={onChangeLogo}
            containerProps={{
              justifyContent: 'start',
            }}
            imageProps={{ height: undefined, width: undefined }}
          />
        </Grid>
      </Grid>
    </>
  );
};
