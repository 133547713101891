import { Grid, Typography } from '@mui/material';
import { useStyles } from './Landing.styles';

export const FeatureTitle = ({
  title,
  text,
}: {
  title: string;
  text: string;
}) => {
  const { classes } = useStyles();

  return (
    <Grid
      item
      xs={12}
      container
      justifyContent="center"
      alignContent="center"
      className={classes.floor5}
    >
      <Grid
        item
        xs={12}
        container
        justifyContent="center"
        alignContent="center"
        flexDirection="column"
      >
        <Typography
          variant="h4"
          className={classes.useCasesTitle}
          textAlign="center"
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          className={classes.useCasesInfo}
          textAlign="center"
        >
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
};
