import { ContactList } from 'common/contact/components/List/ContactList';
import { createRoute } from 'common/helper/createRoute';
import { Navigate } from 'react-router-dom';
import { CommonRouteNames } from '../model/CommonRouteNames';

export const commonRoutes = createRoute([
  {
    path: `/${CommonRouteNames.CONTACT}`,
    element: <ContactList />,
  },
  {
    path: '*',
    element: <Navigate to={`${CommonRouteNames.CONTACT}`} replace={true} />,
  },
]);
