import { darken, lighten } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((_theme) => ({
  card: {
    borderRadius: 10,
    position: 'relative',
    margin: 5,
  },
  planCardColor1: {
    background: '#3099ff',
  },
  planCardColor2: {
    background: '#6099ef',
  },
  planCardColor3: {
    background: '#8099ef',
  },
  planCardColor4: {
    background: 'orange',
  },
  titleCard: {
    background: lighten('#6099ef', 0.6),
    color: darken('#6099ef', 0.8),
    padding: 20,
  },
  title: {
    fontSize: 24,
  },
  infoCard: {
    padding: 20,
  },
  discount: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
}));
