import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((_theme) => ({
  planCard: {
    position: 'relative',
    padding: '20px',
    '& > :not(:last-child)': {
      marginBottom: '20px',
    },
    borderRadius: '10px',
    transition: 'all 0.2s ease-in-out',
  },
  cardHover: {
    '&:hover': {
      boxShadow: '4px 4px 10px 0px rgba(0,0,0,0.2)',
      transform: 'scale(1.05)',
      margin: '0 5px 0 5px',
    },
  },
  badge: {
    borderRadius: '4px',
    position: 'absolute',
    right: '20px',
    top: '22px',
    padding: '5px 10px',
    border: '1px solid',
    fontSize: '1em',
  },
  planDescription: {
    fontSize: '1rem',
    marginTop: '20px',
  },
  planPrice: {
    fontSize: '1.5rem',
    textTransform: 'capitalize',
  },
  planTitle: {
    fontSize: '1.5rem',
    textTransform: 'capitalize',
  },
  disabled: {
    opacity: 0.95,
  },
}));
