import { commonActions } from 'common/context/CommonSlice';
import { useAppDispatch } from 'common/context/hooks';
import { useFetch } from 'common/hooks/useFetchSwr';
import { AdminTablePaths } from 'common/model/AdminTablePaths';
import { Brand } from 'common/model/Brand';
import { useEffect } from 'react';
import { Setting } from 'settings/models/Settings';

export const BrandManager = () => {
  const dispatch = useAppDispatch();

  const { data: brandSettings } = useFetch<Setting>(AdminTablePaths.SETTING);
  const { data: brand } = useFetch<Brand>(AdminTablePaths.BRAND);

  useEffect(() => {
    if (brandSettings) dispatch(commonActions.setSettings(brandSettings));
    if (brand) dispatch(commonActions.setBrand(brand));
  }, [brand, brandSettings, dispatch]);

  return <></>;
};
