import { Grid, Paper } from '@mui/material';
import { accountActions } from 'account/common/context/AccountSlice';
import { AccountRouteNames } from 'account/common/model/AccountRouteNames';
import { Plans } from 'common/components/LandingComponents/Plans';
import { ListWrapper } from 'common/components/ListWrapper/ListWrapper';
import { useAppDispatch } from 'common/context/hooks';
import { createLink } from 'common/helper/createRoute';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const PlanList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSelectHandle = (selected: any, index: number, isYearly: boolean) => {
    dispatch(accountActions.selectPlan({ ...selected, index, isYearly }));
    navigate(createLink(AccountRouteNames, AccountRouteNames.PAYMENT));
  };

  return (
    <ListWrapper title={t('account.selectPlan')}>
      <Grid
        component={Paper}
        justifyContent="center"
        className="min-height100"
        item
        container
      >
        <Grid xs={11} item container>
          <Plans
            style={{
              padding: '60px 0 50px 0',
            }}
            onSelect={onSelectHandle}
          />
        </Grid>
      </Grid>
    </ListWrapper>
  );
};
