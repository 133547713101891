import { Button, Grid, Typography } from '@mui/material';
import { SahinModal } from 'common/components/Modal/SahinModal';
import { SahinTextField } from 'common/components/TextField/SahinTextField';
import { postAPI } from 'common/helper/apiHelpers';
import { AdminTablePaths } from 'common/model/AdminTablePaths';
import { Nullable } from 'common/model/Nullable';
import { forwardRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { mutate } from 'swr';
import { COMPONENT_TABLE_PATH } from '../List/ContactList';
import { ContactFormYup } from './ContactForm.types';
import { Contact } from 'common/model/Contact';

export const ContactForm = forwardRef<
  any,
  {
    contact: Nullable<Partial<Contact>>;
    open: boolean;
    handleClose: () => void;
  }
>(({ contact, open, handleClose }, ref) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<Contact>({
    resolver: ContactFormYup,
    mode: 'onChange',
  });
  const { t } = useTranslation();

  useEffect(() => {
    reset(contact || {});
  }, [contact, reset]);

  const onSubmit = (data: Contact) => {
    postAPI(AdminTablePaths.CONTACT, data).then(() => {
      mutate(COMPONENT_TABLE_PATH);
      handleClose();
    });
  };

  return (
    <SahinModal open={open} handleClose={handleClose} width="50%" height={330}>
      <>
        {!contact?.id && (
          <Grid
            sx={{ height: '100%' }}
            onSubmit={handleSubmit(onSubmit)}
            component="form"
            container
          >
            <Grid spacing={3} container>
              <Grid xs={12} item>
                <SahinTextField
                  {...register('subject')}
                  label={t('form.subject.value')}
                  placeholder={t('form.subject.placeholder')}
                  sx={{ bgcolor: 'rgba(255,255,255,0.05)' }}
                  fullWidth
                />
              </Grid>
              <Grid xs={12} item>
                <SahinTextField
                  {...register('message')}
                  label={t('form.message.value')}
                  placeholder={t('form.message.placeholder')}
                  sx={{ bgcolor: 'rgba(255,255,255,0.05)' }}
                  rows={5}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid xs={12} item>
                {Object.keys(errors).map((item) => (
                  <>
                    {(errors as any)?.[item]?.message}
                    <hr />
                  </>
                ))}
              </Grid>
              <Grid xs={12} justifyContent="flex-end" item container>
                <Button variant="contained" type="submit">
                  {contact?.id ? t('edit') : t('add')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
        {contact?.id && (
          <Grid flexDirection="column" container>
            <Grid
              sx={{
                padding: 1,
                border: '1px solid white',
                borderRadius: 1,
              }}
              spacing={1}
              item
              container
            >
              <Grid
                sx={{
                  borderBottom: '1px solid white',
                }}
                xs={12}
                item
              >
                <Typography variant="h5">İletilen Mesaj</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Konu: {contact.subject}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  İçerik: <br /> &nbsp; {contact.message}
                </Typography>
              </Grid>
            </Grid>
            {contact?.replyMessage && (
              <Grid
                sx={{
                  padding: 1,
                  border: '1px solid white',
                  borderRadius: 1,
                  mt: 2,
                }}
                spacing={1}
                item
                container
              >
                <Grid item xs={12}>
                  <Typography variant="h5">Cevap</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    İçerik: <br /> &nbsp; {contact.replyMessage}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </>
    </SahinModal>
  );
});
