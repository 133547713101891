import { Grid } from '@mui/material';
import { SahinDrawer } from 'common/components/Drawer/SahinDrawer';
import { BrandManager } from 'common/contact/common/components/Manager/BrandManager';
import { ContactManager } from 'common/contact/common/components/Manager/ContactManager';
import { MenuManager } from 'common/contact/common/components/Manager/MenuManager';
import { useAppSelector } from 'common/context/hooks';
import { homeRoutes, welcomeRoute } from 'common/utils/routes';

export const App = () => {
  const auth = useAppSelector((state) => state.common.auth);

  if (!auth) return welcomeRoute;
  return (
    <>
      <MenuManager />
      <BrandManager />
      <ContactManager />
      <Grid
        className="admin"
        sx={{
          height: '100vh',
          overflow: 'hidden',
        }}
        container
      >
        <SahinDrawer>
          <Grid sx={{ height: '100%;' }} direction="column" container>
            <Grid sx={{ flex: 1, height: '100%;' }} container>
              {homeRoutes}
            </Grid>
          </Grid>
        </SahinDrawer>
      </Grid>
    </>
  );
};
