import {
  BorderColorOutlined,
  DeleteOutline,
  LaunchOutlined,
  StarBorderOutlined,
  StarOutlined,
} from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import { ListWrapper } from 'common/components/ListWrapper/ListWrapper';
import { useAppSelector } from 'common/context/hooks';
import { deleteAPI, updateAPI } from 'common/helper/apiHelpers';
import { createLink } from 'common/helper/createRoute';
import { AdminRouteNames } from 'common/model/AdminRouteNames';
import { AdminTablePaths } from 'common/model/AdminTablePaths';
import { dateFormatDayMonthYear, getBrandDomain } from 'common/utils/helper';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { mutate } from 'swr';
import { AddNewMenuDialog } from './AddNewMenuDialog/AddNewMenuDialog';

export const MenuList = () => {
  const menus = useAppSelector((state) => state.common.menus);
  const settings = useAppSelector((state) => state.common.settings);
  const brand = useAppSelector((state) => state.common.brand);

  const [open, setOpen] = useState(false);
  const menuUrl = useMemo(() => getBrandDomain(brand), [brand]);

  const mainMenuHandler = (id: number) => {
    if (id === settings?.menuId) return;
    updateAPI(`${AdminTablePaths.MENU}/active` as AdminTablePaths, id, {}).then(
      () => {
        mutate(AdminTablePaths.SETTING);
      },
    );
  };

  const openAddMenuDialog = () => {
    setOpen(true);
  };
  const closeAddMenuDialog = () => {
    setOpen(false);
  };

  const onDelete = (id: number) => {
    deleteAPI(AdminTablePaths.MENU, id).then(() => {
      mutate(AdminTablePaths.MENU);
    });
  };

  return (
    <ListWrapper
      title="Menüler"
      infoComponent={
        <Button onClick={openAddMenuDialog} color="inherit" variant="outlined">
          Yeni Menü Oluştur
        </Button>
      }
    >
      <Grid
        component={Paper}
        className="min-height100"
        alignContent="start"
        item
        container
      >
        {!settings?.menuId && (
          <Alert
            severity="warning"
            sx={{ width: '100%', mb: 5, letterSpacing: 1 }}
          >
            <Typography fontWeight="bold">Aktif menü yok!</Typography>
            <Box p={0.5} />
            Bir menüyü aktif(⭐️) olarak işaretlemelisiniz yoksa sayfanızda menü
            görünmez
          </Alert>
        )}
        {!menus?.length && (
          <Alert
            severity="info"
            icon="🔖"
            sx={{ width: '110%', mb: 5, letterSpacing: 1 }}
          >
            <Typography fontWeight="bold">Bir menü oluşturmadınız</Typography>
            <Box p={0.5} />
            Hemen menü ekleyerek bu eşsiz deneyime başla!
          </Alert>
        )}
        <Grid p={3} spacing={3} item container>
          {menus?.map((menu) => (
            <Grid key={menu.id} xs={4} item container>
              <Card
                component={Grid}
                justifyContent="space-between"
                direction="row"
                raised
                item
                container
              >
                <Grid sx={{ flex: 1 }} direction="column" container>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5">
                      {menu.name}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      component="div"
                    >
                      {dateFormatDayMonthYear(menu.createdAt)}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      component="div"
                    >
                      {menu.menuData?.length} Alt Başlık
                    </Typography>
                  </CardContent>
                  <Grid
                    alignItems="center"
                    justifyContent="space-between"
                    px={1}
                    pb={1}
                    container
                  >
                    <Grid item>
                      <Tooltip title="Aktif Menü Seç">
                        <IconButton
                          aria-label="main"
                          onClick={() => mainMenuHandler(menu.id)}
                        >
                          {settings?.menuId === menu?.id ? (
                            <StarOutlined color="warning" />
                          ) : (
                            <StarBorderOutlined />
                          )}
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Düzenle">
                        <IconButton
                          component={Link}
                          to={createLink(
                            AdminRouteNames,
                            `${AdminRouteNames.MENU}/${menu.id}`,
                          )}
                          aria-label="Düzenle"
                        >
                          <BorderColorOutlined />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      {settings?.menuId === menu?.id && (
                        <Tooltip title="Menüye git">
                          <IconButton
                            component="a"
                            target="_blank"
                            href={menuUrl}
                            aria-label="Menüye Git"
                          >
                            <LaunchOutlined />
                          </IconButton>
                        </Tooltip>
                      )}{' '}
                      {settings?.menuId !== menu?.id && (
                        <Tooltip title="Sil">
                          <IconButton
                            aria-label="Sil"
                            size="small"
                            onClick={() => onDelete(menu.id)}
                          >
                            <DeleteOutline />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                {/* <CardMedia
                  component="img"
                  sx={{ width: 160 }}
                  image={
                    menu.image ||
                    'https://source.unsplash.com/collection/4466406/100x100'
                  }
                  alt={menu.name}
                /> */}
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <AddNewMenuDialog open={open} onClose={closeAddMenuDialog} />
    </ListWrapper>
  );
};
