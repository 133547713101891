import { Grid, Typography } from '@mui/material';
import { SahinButton } from 'common/components/SahinButton/SahinButton';
import { ProjectRouteNames } from 'common/helper/ProjectRouteNames';
import { createLink } from 'common/helper/createRoute';
import { useResponsive } from 'common/hooks/useResponsive';
import { Link } from 'react-router-dom';
import { Image } from '../Image/Image';
import { useStyles } from './Landing.styles';

export const NavSlider = () => {
  const { classes } = useStyles();
  const { isMd } = useResponsive();

  return (
    <Grid justifyContent="center" container>
      <Grid
        xs={10}
        className={classes.navSlider}
        justifyContent="center"
        alignContent="center"
        item
        container
      >
        <Grid
          lg={5}
          md={12}
          alignItems="start"
          flexDirection="column"
          item
          container
        >
          <Typography variant="h4" className={classes.incomingPageTitle}>
            Kart bilgisi girmeden
            <br /> kayıt ol
          </Typography>
          <Typography variant="body2" className={classes.incomingPageInfo}>
            Qr menü ile baskı maliyetinden anında ücretsiz olarak kurtulun Demo
            ile ürünü direkt deneyin görün.
          </Typography>
          <SahinButton
            href={ProjectRouteNames.REGISTER}
            className={classes.incomingPageButtonNowStart}
          >
            Hemen Başla
          </SahinButton>
          <SahinButton
            className={classes.incomingPageButtonMoreInfo}
            component={Link}
            to={createLink(ProjectRouteNames, ProjectRouteNames.DEMO)}
            soft
          >
            DEMO GÖRÜNTÜLE
          </SahinButton>
        </Grid>
        {isMd.up && (
          <Grid xs={false} lg={7} columnSpacing={3} item container>
            <Grid justifyContent="center" mb={4} mt={-4} item container>
              <Typography
                fontSize={30}
                fontWeight={900}
                sx={{
                  backgroundImage:
                    'linear-gradient(185deg, #59E141 20%, #C0EF17 50%)!important',
                  // 'linear-gradient(185deg, #41E17F 20%, #17EFEB 50%)!important',
                  backgroundClip: 'text',
                  textFillColor: 'transparent',
                }}
                textTransform="uppercase"
              >
                ✨ Ücretsiz Temalarımız ✨
              </Typography>
            </Grid>

            {[0, 1, 2, 3].map((item) => (
              <Grid key={item} xs={3} item>
                <Image
                  src={`/theme-snapshots/${item}.png`}
                  style={{ borderRadius: 5, overflow: 'hidden' }}
                  width="100%"
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
