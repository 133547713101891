import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Typography,
} from '@mui/material';
import { BlogWrapper } from 'blog/components/BlogWrapper/BlogWrapper';

export const SSS = () => {
  const content = (
    <>
      {sss.map((item) => (
        <Accordion key={item.key} sx={{ marginTop: 2 }}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>{item.title}</Typography>
            <Divider />
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              component="pre"
              sx={{ textWrap: 'wrap', paddingRight: 10 }}
            >
              {item.content}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );

  return <BlogWrapper title={title} content={content} />;
};

const sss = [
  {
    key: 1,
    title: 'QR Menü nedir ve nasıl kullanılır?',
    content: `QR Menü, masa üzerinde veya restoranın girişinde bulunan QR kodunu tarayarak dijital menünüze erişim sağlayan bir teknolojidir. Menüyü görmek ve sipariş vermek için QR kodunu tarayabilir veya web tarayıcınızı kullanabilirsiniz`,
  },
  {
    key: 2,
    title: 'Rezervasyon yapabilir miyim?',
    content: `Evet, rezervasyonlarınızı Profesyonel paket seçimiyle önceden yapabilirsiniz. Rezervasyonlarınızı rezervasyon sayfamızdan veya uygulamamız üzerinden gerçekleştirebilirsiniz.
  `,
  },
  {
    key: 3,
    title: 'Faydası Nedir?',
    content: `Dijital menülerin kullanımının birçok faydası vardır. İşte dijital menülerin sağladığı bazı avantajlar:

    Temassız Deneyim:
    Dijital menüler, müşterilere fiziksel bir menüye dokunma ihtiyacını ortadan kaldırarak daha hijyenik bir deneyim sunar, özellikle salgın dönemlerinde önemlidir.

    Hızlı ve Esnek Güncellemeler:
    Dijital menüler, anında güncellenebilir ve değiştirilebilir. Bu, restoran sahiplerine mevsimsel değişikliklere, stok durumlarına veya özel etkinliklere hızla adapte olma yeteneği sağlar.

    Görsel ve Açıklamalı İçerik:
    Dijital menüler, her yemeğin veya içeceğin yanında detaylı açıklamalar, fotoğraflar veya müşteri yorumları gibi ek bilgileri sunma imkanı tanır. Bu, müşterilere daha bilinçli seçimler yapmalarında yardımcı olabilir.

    Sipariş Kolaylığı:
    Müşteriler, dijital menüden kolayca seçim yapabilir ve siparişlerini hızlı bir şekilde verebilir. Bu, servis süreçlerini hızlandırabilir ve müşteri memnuniyetini artırabilir.

    Çeşitli Dil Seçenekleri:
    Dijital menüler, çeşitli dil seçenekleri sunabilir, böylece farklı dil konuşan müşterilere daha iyi hizmet verebilir.

    Toplu İndirim ve Promosyon Yönetimi:
    Dijital menüler, restoran sahiplerine özel indirimler, promosyonlar veya sadakat programları gibi kampanyaları kolayca yönetme imkanı sağlar.

    Maliyet ve Çevre Dostu:
    Dijital menüler, geleneksel kağıt menülerin basım maliyetlerini ortadan kaldırır ve çevre dostu bir seçenek sunar.

    Mobil Ödeme ve Dijital Cüzdan Entegrasyonu:
    Dijital menüler, müşterilere mobil ödeme veya dijital cüzdanlar aracılığıyla ödeme yapma seçenekleri sunabilir.

    Müşteri Geri Bildirimi ve Analitik:
    Dijital menüler, müşterilerin menüde hangi öğeleri incelediğini ve sipariş ettiğini izleyebilir. Bu veriler, işletmelerin müşteri tercihlerini anlamalarına ve stratejilerini buna göre ayarlamalarına yardımcı olabilir.

    Dijital menüler, modern restoran işletmeleri için iş süreçlerini optimize etmek, müşteri memnuniyetini artırmak ve teknolojik yeniliklere ayak uydurmak adına bir dizi avantaj sunar.

`,
  },
];
const title = 'Sık Sorulan Sorular';
