import { Palette, Stack, Typography } from '@mui/material';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

type SwitchPropsType = Omit<SwitchProps, 'size'> & {
  size?: number;
  colorful?: boolean;
};

const IOSSwitch = styled(
  ({ size = 1.5, colorful, color, ...props }: SwitchPropsType) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ),
)(({ theme, size = 1.5, colorful, color }) => ({
  width: 42 * size,
  height: 26 * size,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2 * size,
    transitionDuration: '300ms',
    '& + .MuiSwitch-track': {
      backgroundColor: colorful ? '#6099ef' : '#7F7F7F',
    },
    '&.Mui-checked': {
      transform: `translateX(${16 * size}px)`,
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor:
          (color && (theme.palette as any)?.[color]?.main) || '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22 * size,
    height: 22 * size,
  },
  '& .MuiSwitch-track': {
    borderRadius: (26 * size) / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

interface SahinSwitchProps {
  onBlur?: () => void;
  onChange?: (value: boolean) => void;
  inputRef?: any;
  value: boolean;
  label?: string;
  informativeText?: string;
  sx?: CSSProperties;
  size?: number;
  colorful?: boolean;
  color?: keyof Palette;
}

export const SahinSwitch = ({
  onChange,
  value,
  label,
  informativeText,
  sx,
  size,
  colorful,
  color,
}: SahinSwitchProps) => {
  const onChangeHandler = (_: any, value: boolean) => {
    onChange?.(value);
  };

  return (
    <Stack
      direction="row"
      justifyContent="center"
      spacing={3}
      alignItems="center"
      sx={sx}
    >
      {label && <Typography variant="body2">{label}</Typography>}
      <IOSSwitch
        checked={value}
        size={size}
        colorful={colorful}
        color={color as any}
        onChange={onChangeHandler}
      />
      {informativeText && <Typography>{informativeText}</Typography>}
    </Stack>
  );
};
