import {
  BusinessOutlined,
  ChatOutlined,
  DarkMode,
  HistoryEduOutlined,
  PowerSettingsNewOutlined,
  QrCode2Outlined,
  SettingsOutlined,
} from '@mui/icons-material';
import { Chip } from '@mui/material';
import { AccountRouteNames } from 'account/common/model/AccountRouteNames';
import { CommonRouteNames } from 'common/contact/common/model/CommonRouteNames';
import { commonActions } from 'common/context/CommonSlice';
import { useAppDispatch, useAppSelector } from 'common/context/hooks';
import { store } from 'common/context/store';
import { createLink } from 'common/helper/createRoute';
import { AdminRouteNames } from 'common/model/AdminRouteNames';
import { ThemeMode } from 'common/model/ThemeMode';
import { SahinSwitch } from '../SahinSwitch/SahinSwitch';
import { DrawerMenuList } from './SahinDrawer.types';

const ChangeThemeSwitch = () => {
  const themeMode = useAppSelector((state) => state.common.theme);
  const dispatch = useAppDispatch();
  const toggleTheme = () => {
    const newTheme =
      themeMode === ThemeMode.LIGHT ? ThemeMode.DARK : ThemeMode.LIGHT;
    dispatch(commonActions.setTheme(newTheme));
  };

  return (
    <SahinSwitch
      value={ThemeMode.DARK === themeMode}
      size={0.8}
      color="primary"
      onChange={toggleTheme}
    />
  );
};

export const menuList = ({ open, theme }: any): DrawerMenuList[] => [
  {
    id: 1,
    menu: [
      {
        label: 'Menü Yönetimi',
        link: createLink(AdminRouteNames, AdminRouteNames.MENU),
        icon: HistoryEduOutlined,
      },
      {
        label: 'QR Editör',
        link: createLink(AdminRouteNames, AdminRouteNames.CORPORATEL_IDENTITY),
        icon: QrCode2Outlined,
      },
    ],
  },
  {
    id: 2,
    menu: [
      {
        label: 'Hesap Yönetimi',
        link: createLink(AccountRouteNames, AccountRouteNames.PLAN),
        icon: BusinessOutlined,
        badge: (
          <Chip label="FREE" color="primary" variant="outlined" size="small" />
        ),
      },
      {
        label: 'Destek',
        link: createLink(CommonRouteNames, CommonRouteNames.CONTACT),
        icon: ChatOutlined,
      },
      {
        label: 'Ayarlar',
        link: createLink(AdminRouteNames, AdminRouteNames.SETTING),
        icon: SettingsOutlined,
      },
      {
        label: 'Dark Mode',
        action: () =>
          !open &&
          store.dispatch(
            commonActions.setTheme(
              ThemeMode.LIGHT === theme ? ThemeMode.DARK : ThemeMode.LIGHT,
            ),
          ),
        icon: DarkMode,
        badge: <ChangeThemeSwitch />,
      },
      {
        label: 'Çıkış Yap',
        action: () => store.dispatch(commonActions.setAuth(null)),
        icon: PowerSettingsNewOutlined,
      },
    ],
  },
];
