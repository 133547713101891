import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  root: {
    // '& .MuiFormLabel-root': {
    // color: '#333',
    // },
    // '& label.Mui-focused': {
    // color: 'black',
    // },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
      color: 'red',
    },
    '& .MuiOutlinedInput-root': {
      '& input': {
        // color: '#000',
        letterSpacing: '.05em',
      },
      '& input:foces': {
        color: 'red',
      },
      // '& fieldset': {
      // borderColor: '#3e3e3e',
      // },
      // '&:hover fieldset': {
      // borderColor: '#3D3D3D',
      // },
      // '&.Mui-focused fieldset': {
      // borderColor: '#333',
      // },
    },
  },
}));
