import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  text: {
    background: 'transparent!important',
    boxShadow: 'none!important',
  },
  soft: {
    opacity: 0.9,
    boxShadow: 'none!important',
  },
});
