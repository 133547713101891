import { Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { SahinButton } from '../SahinButton/SahinButton';
import { useStyles } from './Landing.styles';

export const WhatThatIt = ({
  title,
  buttonText,
  buttonLink,
}: {
  title: string;
  buttonText?: string;
  buttonLink?: string;
}) => {
  const { classes } = useStyles();

  return (
    <Grid
      item
      xs={12}
      container
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      className={classes.floor4}
    >
      <Typography variant="h4" textAlign="center" color="black">
        {title}
      </Typography>
      <SahinButton
        component={Link}
        to={buttonLink}
        className={classes.incomingPageButtonMoreInfo}
        soft
      >
        {buttonText}
      </SahinButton>
    </Grid>
  );
};
