import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{
  width: number | string;
  height: number | string;
}>()((_theme, { width, height }) => ({
  root: {
    width: width,
    height: height,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#fff',
    border: '2px solid #999',
    boxShadow: '5px 5px 10px rgba(0, 0, 0, .2)',
    padding: _theme.spacing(6, 0, 1, 0),
    borderRadius: 5,
  },
  close: {
    position: 'absolute',
    top: '0',
    left: '0',
  },
  closeIcon: {
    color: '#999',
  },
  content: {
    height: '100%',
    overflow: 'hidden auto',
    padding: _theme.spacing(1, 4, 0, 4),
  },
}));
