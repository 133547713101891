import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((_theme) => ({
  root: {
    background: _theme.palette.background.paper,
    height: '100%',
    padding: '0 0px',
  },
  title: {
    padding: '0 15px',
    height: 60,
    borderBottom: '1px solid #22222250',
  },
  contentWrap: {
    height: '100%',
    flex: 1,
    overflow: 'hidden',
  },
  content: {
    height: '100%',
    overflow: 'auto',
    padding: 20,
    background: _theme.palette.background.default,
  },
  footer: {
    height: 60,
    borderTop: '4px solid #222',
  },
}));
